

.dropdownContainer {
    background-color: var(--tuna);
    color: var(--manatee);
    text-align: left;
    padding: 10px;
    border: 0px;
    border-radius: 8px;
    
  }
  
  .dropdownHeader {
    background-color: var(--tuna);
    color: var(--manatee);
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    
  }
  
  .dropdownArrow {
    font-size: 0.8em;
    justify-self: flex-end;
  }
  
  .dropdownList {
    width: 100%;
    background-color: var(--tuna);
    border-radius: 0 0 8px 8px;
    overflow: hidden;
    height: fit-content;
    z-index: 1000;
    padding-top: 10px;
  }
  
  .dropdownItem {
    padding: 10px;
    color: var(--swiss-coffee);
    background-color: var(--tuna);
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .dropdownItem:hover {
    background-color: var(--gun-powder);
  }
  