@import "../../globalVariables.scss";

.container {
  width: 75vw;
  display: flex;
  flex-flow: column;
  align-items: center;

  @include mobile {
    width: 330px;
  }

  @include tablet {
    width: 700px;
  }

  @include desktop-md {
    width: 950px;
  }
}
