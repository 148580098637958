.componentMain{
    background-color: var(--mine-shaft);
    display: flex;
    width: auto; 
    height: fit-content;
    min-height: calc(100vh - 51px); 
    flex-direction: column; 
    justify-content: center; 
    align-items: center;
    padding-bottom: auto;
    padding-top: 70px;

    


}
.mainLabel{
    color: var(--swiss-coffee);
    font-size: 16pt;
    font-weight: 300;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    align-self: center;

}
.listContainer {
    color: var(--swiss-coffee);
    display: flex;
    flex-direction: row;
    
    font-size: 16px;
    
    justify-content: space-between;
    margin: 20px 0px 0px 20px;
    align-items: flex-end;
    border-bottom: 1px solid var(--mako);
    
}
.cardMain, .cardSpeedTest{
    border: #FB6520 0px solid;
    background-color: var(--mine-shaft);
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    border-radius: 15px;
    box-shadow: 
        3px 3px 10px #00000032, /* Darker shadow on the bottom right */
        -3px -3px 10px rgba(80, 80, 80, 0.144); 
    height: fit-content;
    width: 450px;

    max-width: 450px;
}
.cardSpeedTest{
    background-color: var(--swiss-coffee);
    box-shadow: 
    5px 5px 10px #000000fa, /* Darker shadow on the bottom right */
    -5px -5px 10px rgba(255, 255, 255, 0.205); 
}
.playerContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    overflow: hidden;
    justify-content: center;
    align-items: center;
    align-self: center;
    justify-self: center;
    
}
.longResponse{
    color: var(--swiss-coffee);
    font-size: x-small;
    max-width: 300px;
    overflow-x: wrap;
}
.iconContainer{
    display: flex;
    height: 25px
}
.videoResultText{
    color: var(--swiss-coffee);
    font-size: 16pt;
    display: flex;
    text-align: center;
    padding-right: 10px;
    margin: 0;

}
.devicesList{
    color: var(--swiss-coffee);
    font-size: 10pt;
    line-height: 13px;
    text-align: right;
    padding: 5px;

}
.listLabel{
    color: var(--swiss-coffee);
    text-align: left;
    font-size: 13px;
}
.listSubContainer{
    display: flex;
    flex-direction: row;
    align-items: start;
    margin-left: 20px;
    padding: 0;
    margin-top: 5px;
    justify-content: space-between;
}
.listItemContainer{
    display: flex;
    flex-direction: column;
    width: auto;
}

.playerLoading{

    width: auto;
    height: auto;
    display: flex; 
    flex-direction: row;
    border-radius: 15px;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: var(--swiss-coffee); 
    z-index: 10;
    margin: 10px 0 0 0;
    
}
.componentSub{
    display: flex;
    flex-direction: row;
    width: 85vw;
    gap: 20px;
    align-items: flex-start;
    justify-content: center;
    height: stretch; 
    padding-bottom: 100px;
}
@media screen and (max-width: 960px) {
   
    
    .componentSub{
        align-items: center;
        flex-direction: column;
        width: stretch;
        height: auto; 
        overflow: visible;
        justify-content: flex-end
         
    }
    .cardMain{
        min-width: 400px;
        margin-bottom: 50px;
        justify-content: center;
        align-items: center;
    }
}