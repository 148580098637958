.containerBKG{
  background-color: var(--mako);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-xl);
  padding: var(--gap-xl);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  width: auto;

  margin:  20px;
    height: fit-content;
    margin-top: 75px;
}
.linkContainer{
    display: grid;
    /*a grid with three colums and lots of rows*/
    grid-template-columns: repeat(3, auto);
    
    width: stretch;
    gap: 20px;
    height: 45px;
    
}