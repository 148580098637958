.helpComponent {
    display: flex;
    flex-direction: column;
    background:#202020;
    color:#DDD8D8;

    width: auto; 
    padding-top: 70px;
  
    
    justify-content: start;
    min-height: calc(100vh - 51px);
    height: fit-content;
    align-items: center;
}
.contentContainer{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    margin: 0;
    height: fit-content;
    align-self: center; 
    gap: 20px;

    

    
}
.searchContainer{
    display: flex;
    align-items: center;
    flex-direction: column;
    width: auto; 
    padding: 0; 
}
.headlineText{
    font-size: 48px;
    display: flex;
  

    margin-top: 50px;
    margin-bottom: 20px;
    text-align: center;
    align-content: center;

}

.gridCard{
    display: flex;
    flex-direction: column;
    height: 300px;
    min-width: 300px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 25px;
    justify-content: space-between;
    border: 1px solid #FB6520;
    align-self: center;
    background: linear-gradient(to bottom, #343139, #202020);
    color: #DDD8D8;
    border-radius: 18px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.gridCard:hover{
    background: #343139;
}
.gridCard:active{
    box-shadow: inset 0 0 10px 5px rgba(0, 0, 0, 0.5);

}
.gridContainer{
    width: 75vw;
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    margin: 20px;

}
.gridCardChildContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: stretch;
    width: stretch;
}
.cardHeader{
    color: var(--Orange, #FB6520);

font-size: 30px;
font-style: normal;
font-weight: 300;
line-height: normal;
text-transform: capitalize;
text-align: left;

}
.cardText{
    width: 200px;
   
    margin: 0;
}
.cardTextRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    width: stretch;
    font-weight: 300;

    
    
}
.doubleArrow{
    height: 2vh;
    
    
}

.contactContainer{
    display: flex;
    gap: 10px;
    height: 10vh;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin: 0;
    padding: 0;
    width: stretch;
    min-width: fit-content;
}
.contactCopy{
    padding-right: 5vw;
    color:#DDD8D8
}
.contactButton{
    background: linear-gradient(to bottom, #FD6500, #511A1A);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    color: #DDD8D8;
    border-radius: 10px;
    border-width: 0px;
    border-style: solid;
    font-size: x-large;

    min-width: fit-content; 
    width: 300px;

}
.contactButton:hover{
    background: linear-gradient(to bottom, #f68437, #5c2b2b);


}

.danteCloseButton{

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    color: #DDD8D8;
    border-radius: 10px;
    border-width: 1px;
    border-color: #FD6500;
    border-style: solid;
    font-size: x-large;
    width: 300px;
    font-size: 14pt;
    text-align: center;
    min-width: fit-content;
 
    

}


.supportButton{

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    color: var(--orange);
    border-radius: 10px;
    border-width: 1px;
    border-color: #FD6500;
    background-color: #FD650025;
    border-style: solid;
    font-size: x-large;
    width: 300px;
    font-size: 14px;
    text-align: center;
    min-width: fit-content;
    cursor: pointer;
    

}
.videoContainer{
    height: 500px;
    width: 980px;
}
.video{
    height: 100%;
    width: 100%;
    min-width: 400px;
    border-radius: 15px;

}

.closeBotButton{
    position: relative;
    z-index: 10;
    display: flex;

    background-color: var(--mine-shaft);
    border: 1px solid #FD6500;
    width: auto;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 10px;
    cursor: pointer;

}


.supportButton:hover{
    background: #101010


}
.danteBox{
    box-shadow: 5px 5px 10px #202020, -5px -5px 10px #252525
}
.hz{
    margin: 40px;
    gap: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    

}
.fadeIn {
    animation: fadeIn 2s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: fit-content;
  }
  
  @keyframes fadeIn {
    from {
      
      opacity: 0;
    }
    to {
     
      opacity: 1;
    }
}
.xCircle{
    position: absolute;
    top: 4%;
    left: 2%;
    border-color: black;
    border-width: 1px;
    border-style: solid;
    border-radius: 10px;
    width: min-content; 
    height:min-content;
    cursor: default;
    color: rgb(0, 0, 0); 
    text-align:center;
    padding: 1%;
   
    z-index: 2;
    
}
.xCircle:hover{
  

    border-color: #FD6520;
    color: #FD6520;

}
.chatTopBar{
    background-color: transparent;
    width: 100%;
    height: auto;
    margin:0; 

    
}
.DanteContainer{
        
        position: relative;
        border-radius: 8px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: auto; 
        width: fit-content;
        
}
a{
    text-decoration: none;
}
a:link{
    color:inherit
}
a:visited{
    color:inherit
}

.mainHelpContentContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 100%;
    height: fit-content;
    margin-top: 20px;
}

@media screen and (max-width: 1000px) {

    .gridContainer{
        display: flex;
        flex-direction: column;
    }
    .hz{
        flex-direction: column;
    }
}

@media screen and (max-width: 540px) {
    .gridCard{
        height: fit-content;
    }
    .cardHeader{
        font-size: 18px;
    }
    .helpComponent{
        /*min-height: stretch;*/
        height: fit-content;
        justify-content: flex-start;
       
    }
    .headlineText{
        font-size: 24px;
    }
    .videoContainer{
        height: 300px;
        width: 400px;
        
    }

}
