.notify{
    display: flex;
    position: absolute;
    width: auto;
    top: 20%;
    border-radius: 8px;
    align-self: center;
    background-color: var(--mine-30);
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 10px 20px 10px 20px;
    animation: modalFadeIn 0.5s;
}

@keyframes modalFadeIn{
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}
@keyframes modalFadeOut{
    from{
        opacity: 1;
    }
    to{
        opacity: 0;
    }
}
.modalOff{
    display: none
    
}
.modalContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;

}
.errorCode, .successCode{
    font-size: 1rem;
    font-weight: bold;

}
.errorCode{
    color: var(--error-red);
}
.successCode{
    color: var(--success-green);
}

.notifyText{
    font-size: 1rem;
    font-weight: bold;
    color: var(--swiss-coffee);

}
.errorCloseButton, .successCloseButton{
   
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--mine-shaft);
    border-radius: 100px;
    font-size: 1rem;
    font-weight: bold;

    cursor: pointer;
}
.errorCloseButton{
    color: var(--error-red);
    border: 2px solid var(--error-red);
}
.successCloseButton{
    color: var(--success-green);
    border: 2px solid var(--success-green);
}
