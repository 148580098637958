.label {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin-top: 5vh;
}
.centerFrame{
  display: flex;
  flex-direction: column;
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
  linear-gradient(0deg, #44444d, #000) ;
  justify-content: flex-start;
  align-items: center;
  height: stretch;
  min-height: fit-content;

}



.label .termsConditions {
  color: transparent;
  
  font-size: 36px;
  font-weight: 500;
  width: 75vw;
  letter-spacing: 0;
  line-height: normal;
  
  


}

.label .textWrapper {
  font-family: "Arial-Bold", Helvetica;
  color: #ddd8d8;
}

.label .span {
  font-family: "Arial-Bold", Helvetica;
  color: #ddd8d8;
}

.bodyText{
  display: flex; 
  flex-direction: column;
  font-family: "Arial-Bold", Helvetica;
  color: #ddd8d8;
  width: 75%;
  text-align: justify;
  margin-bottom: 10vh; 

}
.helpPageContainer{
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
  linear-gradient(0deg, #44444d, #000);
  height: 1050vh;
  justify-content: stretch;
  align-items: stretch;

  
}
.termsPageContainer{
  display: flex;
  flex-direction: column;
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
  linear-gradient(0deg, #44444d, #000);
  min-height: 100vh;
  height: fit-content;
  justify-content: stretch;
  align-items: stretch;
  
  
}

