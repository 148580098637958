.radialMenu {
    position: relative;
    width: auto; /* Control size of the circle */
    height: auto; /* Control size of the circle */
    border-radius: 50%;
    margin: 0px auto; /* Centering the menu */
    transform: rotate(17.5deg);
    transform-origin: 50% 50%;


}

.slice {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40vw; /* Half of the circle's diameter */
    height: 29vw; /* Half of the circle's diameter */
    background: linear-gradient( var(--mine-shaft), #000000);
    border: none;
    
    clip-path: polygon(50% 50%, 100% 0, 100% 100%);
    transform-origin: 50% 50%;
    transition: background-color 0.3s, transform 0.3s;
}

.slice:hover,
.slice.active {
    background-color: deepskyblue;
}
