.searchResultButton{
    color: #DDD8D8;
    width: auto;
    height: auto;
    margin: 1vh;
    border-radius:10px;
    background: #343139; /* Light background color */
    box-shadow: 5px 5px 10px #202020, -5px -5px 10px #252525; /* Neumorphic box shadow */
    cursor: pointer;
    padding: 16px;
}
.searchResultButton:hover{
    background: #ff6a00aa
}
.searchButton{
    justify-content: center; 
    margin-left: 10px; 
    width: 5vh; 
    height: 5vh; 
    border-radius: 1px; 
}