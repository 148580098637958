.mainComponent {
  
    display: flex;
    flex-direction: column;
    align-self: center;
    padding-top: 20px;
    align-self: center;

    height: auto;
    width: stretch;
    justify-content: space-evenly;

}
.planTypeHeader{
    font-size: xx-large;
    margin-right: 10px;
   

}

.standardText{
    font-size: 12pt;
}
.funnelSubHeader{
    font-size: 24px;
    color: var(--swiss-coffee);
    
   
    
    flex-direction: row;
    text-align: center;
    font-weight: 400;
    align-self: center;
   
  
  }

.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    
    width: 320px;
    
    
    background-color: #2C2C32;
    border-radius: 6px;
    color: var(--swiss-coffee);
    text-align: center;
    box-shadow: 2px 2px 10px rgb(0, 0, 0), -2px -2px 10px rgba(110, 110, 110, 0.558);
    border-color: #44444D;
    border-style: solid;
    border-width: 1px;
    justify-content: space-between;


}
.hzContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.cardComingSoon {
    
    opacity: 75%;
    height: 100%; 

}



.priceContainer{
    display: flex;
    flex-direction: column;
    height: 130px;
    align-self: center;
    background-color: transparent;
    padding: 20px 20px 20px 20px;
    width: stretch;
    
}

.contactLine{
    color: var(--swiss-coffee);
    text-align: center;

}
.detailsText{
    display: flex;
    justify-self: flex-start;
    align-self: start;
    text-align: left;
    height: 40px;
    padding: 0px 10px 20px 0px;
    margin-bottom: 10px;
    font-size: 14px;
    
}
.detailsContainer{
    display: flex;
    flex-direction: column;
    background-color: var(--tuna) ;
    width: stretch;
    height: auto;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 15px;
    border: 1px solid transparent;

 
}
.detailsButton{
    text-decoration: underline;
    margin: 2vh;
    color: grey;
    cursor: pointer;
    
}

.cardSelected {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;

    width: 300px;
    

    background-color: #2C2C32;
    border-radius: 6px;
    color: var(--swiss-coffee);
    text-align: center;
    box-shadow: 2px 2px 10px rgb(0, 0, 0), -2px -2px 10px rgba(110, 110, 110, 0.558);

    border-style: solid;
    border-width: 2px;
    border-color:#FB6520 ;
    justify-content: space-between;
    transition: border-color 0.5s ease-in-out;
    
    transition: height 1s ease-in-out;


}

.cardCurrent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: fit-content;
    min-height: 600px;
    width: 300px;
    
    padding: 30px;
    background-color: #090909;
    border-radius: 6px;
    color: grey;
    text-align: center;
    box-shadow: 2px 2px 3px rgb(8, 8, 8), -2px -2px 3px rgb(132, 132, 132);

    border-style: solid;
    border-width: 1px;
    border-color: rgb(135, 135, 135);
    transition: border-color 0.5s ease-in-out;
  
    transition: height 1s ease-in-out;

}


.buttonReg {
    background: linear-gradient(
        180deg,
        rgba(251, 101, 32, 0.86) 31.12%,
        rgba(251, 101, 32, 0.72) 75.75%
      );    
      box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.68), -2px -2px 10px rgba(255, 255, 255, 0.284);
    border-radius: 6px;
    border: 2px solid transparent;

    text-align: center;
    color:var(--swiss-coffee)
}



.buttonFunnel {
    background: linear-gradient(
        180deg,
        rgba(251, 101, 32, 0.86) 31.12%,
        rgba(251, 101, 32, 0.72) 75.75%
      );    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.428), -2px -2px 10px rgba(255, 255, 255, 0.19);

    border-radius: 6px;
    text-align: center;
    width: 100%; 
    height: auto;
    padding: 10px;
    border: 2px solid transparent;
    
}
.buttonFunnel:active, .buttonReg:active {
    background: linear-gradient(to top, #e57a48, #FB6520);
    box-shadow: inset -2px -2px 10px rgba(255, 255, 255, 0.284), inset 2px 2px 10px rgba(0, 0, 0, 0.68);
   

    border: 2px solid transparent;

}
.buttonFunnel:hover, .buttonReg:hover{
    background: linear-gradient(to top, #ce7d57, #e15717);


}

.buttonSelected {
    background: linear-gradient(to top, #03b921, #048401);
    box-shadow: inset -2px -2px 10px rgba(255, 255, 255, 0.313), inset 2px 2px 10px rgba(0, 0, 0, 0.373);

    border-radius: 6px;
    text-align: center;
    color: var(--swiss-coffee)



}

.buttonCurrent {
    background-color: transparent;
    box-shadow: inset -2px -2px 10px rgba(255, 255, 255, 0.313), inset 2px 2px 10px rgba(0, 0, 0, 0.373);

    border-style: solid;
    border-width: 1px;
    border-radius: 6px;

    border-color: green;
    text-align: center;

}

.buttonCurrent:hover {
    background-color: rgb(9, 9, 9);
    border-style: solid;
    border-width: 1px;
    border-radius: 6px;

    border-color: green;
    color: var(--swiss-coffee);
    text-align: center;

}

.buttonComing {
    background-color: rgb(90, 90, 90);
    border-style: solid;
    border-width: 1px;
    border-radius: 6px;
    text-align: center;

    border-color: rgb(71, 71, 71)
}

.buttonComing:hover {
    background-color: rgb(90, 90, 90);
    border-style: solid;
    border-width: 1px;
    border-radius: 6px;
    text-align: center;

    border-color: rgb(71, 71, 71);
    color: var(--swiss-coffee)
}
.subSelectorContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: end;
    width: 100%;
    height: auto;
}

.cardSpecOff{

    width: stretch;
    padding-left: 10px;

}
.cardSpecItems{

    width: stretch;
    margin: 0px; 
}

.cardSpecList{
    max-height: 375px;
    width: stretch;
    margin: 0; 
}
.buttonBase {
    width: stretch;
}

.cardContainer {
    height: auto;
    display: flex;
    justify-self: center;
    align-self: center;
    width: 55vw;
    padding: 10px;
    border-radius: 8px;
    gap: 15px;
    justify-content: center;

}
@media screen and (max-width: 1000px) {
    .cardContainer {
        height: auto;
        display: flex;
        justify-self: center;
        align-self: center;
        width: 85vw;
        padding: 10px;
        border-radius: 6px;
       
        justify-content: center;
        flex-direction: column;
        align-items: center;
        gap: 20px;
    
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}


.selectorOn {
    display: flex;
    justify-content: center;
    background: linear-gradient(to bottom, #202020, #252525) ;
    margin: 0;
    box-shadow: inset -2px -2px 10px rgba(255, 255, 255, 0.056), -2px -2px 10px rgba(255, 255, 255, 0.222), 2px 0px 10px rgba(0, 0, 0, 0.68);  

    font-size: 12px;
    width: stretch;
    z-index: 2;
    color: var(--orange);
    min-height: 40px;
    text-align: center;
    align-items: center;
    border: #FB6520 1px solid;
    border-bottom: 0px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    cursor: pointer;
}
.blueCheck{
    align-self: center;
    width: 15px;
    height: 15px
}


.selectorOff {
    display: flex;
    justify-content: center;
    margin: 0;
    min-height: 40px;
    opacity: 75%;
    background: linear-gradient(to left top, #525252, #44444D);
    box-shadow: -2px -2px 10px rgba(255, 255, 255, 0.222), 2px 0px 10px rgba(0, 0, 0, 0.68);  

    border: grey 1px solid;
    align-items: center;
    border-bottom: #FB6520 1px solid;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    font-size: 12px;
    background-color: 313139;
    width: stretch;
    text-align: center;
    cursor: pointer;
}
.selectorOff:hover{
    background: linear-gradient(to left top, #757575, #595960);

}

.mainSelector {
    display: flex;
    flex-direction: row;
    align-self: center;
    align-items: center;
    justify-content: center;
  
    

}

.mainSelectorWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    cursor: pointer;
    width: auto; 
    
    padding-left: 5px;
    padding-right: 5px;
    height: auto;
    border-radius: 100px;
    border: #FB6520 2px solid;
  
    
}
.orangeText{
    color: #FB6520
}

.mainSelectorOn {
    z-index: 1;
    width: 200px;
    padding: 0;
    padding-top: 10px;
    padding-bottom: 10px;

    margin-top: 5px;
    margin-bottom: 5px;
    color: var(--orange);
    text-align: center;
    transition: color 1s ease-out;
      
    
}
.mainSelectorOff {
    padding: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    z-index: 1;
    width: 200px; 
    text-align: center;
    background-color: transparent;
    color: var(--swiss-coffee);
    transition: color 1s ease-out;

}
.mainSelectorFree {
    z-index: 1;
    min-width: 200px;
    padding: 0;
    padding-top: 10px;
    padding-bottom: 10px;

    margin-top: 5px;
    margin-bottom: 5px;
    color: var(--orange);

    text-align: center;
    transition: color 1s ease-out;

      
    
}
.mainSelectorSliderOT{
    position: relative;
    z-index: 0;
    background-color:  var(--mako);
    width: 200px;
    height: 40px;
    padding: 0px;
    margin-left: -200px;
    border-radius: 100px;
    transform: translate(200px, 0px);

    transition: transform 0.5s ease-in-out; /* Smooth transition for the movement */

}
.mainSelectorSliderSB{
    position: relative;
    z-index: 0;
    background-color:  var(--mako);
    margin-left: -200px;
    width: 200px;
    height: 40px;
    transform: translate(400px, 0px);
    border-radius: 100px;
    transition: transform 0.5s ease-in-out; /* Smooth transition for the movement */

}
.mainSelectorSliderFR{
    position: relative;
    z-index: 0;
    background-color: var(--mako);
    width: 200px;
    height: 40px;
    padding: 0px;
    margin-left: -200px;
    transform: translate(200px, 0px);

    border-radius: 100px;

    animation: transform 0.5s ease-in-out; /* Smooth transition for the movement */

}
.basicDiv{
    display: flex;
    flex-direction: row;
    justify-content: left;
    width: stretch;
    gap: 10px;
    
    

}
.cardSpecItemSpacer{
    display: flex;
    flex-direction: row;
    justify-content: justify;
    gap: 40px;
    width: stretch;
    
 
}
.cardSpecListOff{
    display: flex;
    flex-direction: column;
    margin-top: -25px;
    border: 0px;
    align-items: start;
    padding: 10px; 
    transition: height 1s ease;
    
}
.cardSpecItems{
    display: flex;
    flex-direction: column;
    border: #FB6520 1px solid;
    border-top: 0px; 
    background: linear-gradient(to bottom, #252525, #2A2A2A);
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.68), -2px -2px 10px rgba(255, 255, 255, 0.284);  

    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    align-items: start;
    padding: 10px 10px 20px 10px; 
    
    
}
.listGridItem{
    height: min-content;
    align-self: center;
    align-items: center;
    justify-content: center;
    text-align: left;
    width: 'stretch';
    margin: 20px 0px 20px 10px;

}

.listGridContainer{
    max-height: 40px;
    height: 40px;
    display: grid;
    grid-template-columns: 15px 135px 125px; /* 3 columns with equal width */
    grid-gap: 5px; /* Gap between grid items */
    padding-bottom: 10px;
    font-size: 16px;
}
.listGridIcon{
    display: inline-block;
    height: 20px;
    
    align-self: center;
    align-items: center;
    justify-content: center;
    text-align: left;

}





.cardDeselectButton {
    align-self: flex-start;
    position: absolute;
    border:rgb(90, 90, 90) 1px solid;
    border-radius: 100px;
    margin-left: 225px;
    width: 25px;
    height: 25px;
    padding: 2px;
    padding-right: 4px;
    cursor: default;
    box-shadow: 1px 1px 1px rgb(8, 8, 8), -1px -1px 1px rgb(132, 132, 132);
  
}
.cardDeselectButton:hover {
    align-self: flex-start;
    position: absolute;
    border:rgb(90, 90, 90) 1px solid;
    border-radius: 6px;
    width: 25px;
    height: 25px;
    padding: 2px;
    background-color: #44444D;

    
}

.purchaseButton {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1vh;
    height: 40px;

    background-color: transparent;
    border-color: #FB6520;
    color: #FB6520;
    border-width: 1px;
    border-style: solid;
    border-radius: 6px;
    text-align: center;
    
}

.purchaseButtonSpacer {
    margin-top: 1vh;

    height: 5vh;
    align-self: center;
}
.funnelText{
    color: var(--swiss-coffee); 
    text-align: center;
    margin-top: 20px;
    width: 70%
}

.funnelPrice{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    justify-content: center;
    background-color:#202020;
    border:rgb(80, 80, 80) 1px solid;
    border-radius: 18px;
    padding: 0;
    width: 100%; 
    margin-bottom: 1vh;
    margin-top: 1vh;
    height: 75px;
    font-size: 16px;
    color: var(--manatee)
    
}
.funnelContainer{
    width: 100%;
    gap: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

}
.funnelComponent{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    align-self: center;
    width: 70%;
}
.greyText{
    color: var(--manatee);
    width: fit-content;
    padding: 10px;
}
.funnelCard {


    min-width: 370px;
    padding: 20px;
    padding-top: 60px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    
    align-self: stretch;
    display: flex;
    align-items: center;
    height: min-content;
    
    
    background: linear-gradient(to bottom, #4949578A 0%, #20202020 50%);
    border-radius: 6px;
    border: 1px solid #313139;
    color: var(--swiss-coffee);
    text-align: center;
    
 
    transition: border-color 0.5s ease-in-out;
    animation: fadeIn .2s ease-in-out;
    transition: height 1s ease-in-out;

}
.funnelHeader{
    font-size: 40px;
}
.headerText{
    font-weight: 300;
    font-size: 36px;
    margin: 30px 0px 30px 0px; 
    align-self: center;
    text-align: center;
    width: 525px;
    max-width: 100vw;
    line-height: 45px;
  }

.smallPrice{
    font-size: 40px;
    color: var(--swiss-coffee)
}

.bigPrice{
    font-size: 48px;
    font-weight:600;
    margin: 0;
    height: 45px;
    align-self: flex-start;
    
}
.mediumPrice{
    margin: 0;
    display: flex;
    font-size: 24px;
    text-align: left;
    height: 45px;
    align-self: flex-start;

}
.priceLabel{
    align-self: flex-end;
    padding-left: 5px;
    height: stretch;
    font-weight: 500
}

.contactLink{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 5px;
    align-items: center;
    margin: 25px 0px 25px 0px;
    color: var(--swiss-coffee)
}
.productLabel{
    font-weight: 400; 
    align-self: start;
    font-size: large;
    margin-top: 0;
    font-size: 24px;
}

.promoContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: fit-content;
    height: 50px;
    color: var(--swiss-coffee);
    border-radius: 6px;
    padding: 0;
    margin-bottom: 1vh;
    margin-top: 1vh;
    height: 50px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;

}
.promoCardParent{
    display: flex;
    justify-content: center;
    flex-direction:column;
    align-items: center;
   
    animation: fadeIn .5s ease-out;
    
}
.promoImage{
    width: 250px;
    height: auto;

  


}
.linkTextWhite{
    color: var(--swiss-coffee);
    font-size: 20px;
    padding: 10px 0px 10px 0px;
}
.linkTextOrange{
   
    font-size: 20px;
    padding: 10px 0px 10px 0px;
    text-decoration: underline;
}
.greenText{
    color: var(--success-green)
}
.promoText{
    display: flex;
    flex-direction: row; 
    gap: 7px;
    color: var(--swiss-coffee);
    text-align: center;
    margin: 0;
    padding: 0;
    
}
.promoTextHighlight{
    color: #FABC35
}
.promoTextBanner, .promoTextBannerSm, .promoTextBannerBlue{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4px;
    font-size: 10px;
    color: #FABC35;
    background-color: #594D39;
    padding: 0 14px 0 14px;
    height: 36px;
    border: 1px solid #FABC35;
    border-radius: 6px;
}
.promoTextBannerSm{
    height: 15px;
    
    padding: 8px 5px;
    margin-left: 2px;
    border-radius: 3px;
    width: fit-content;
}
.promoTextBannerBlue{
    background-color: #0089CC;
    border: 0px;
    color: var(--mine-shaft);
    height: 25px;
    width: 100px;
    padding: 5px;
    font-size: 12px;
}



@media screen and (max-width: 960px) {
    .funnelCard{
        min-width: 200px;
    }
    .contactLink{
        flex-direction: column;
        margin: 0;
    }
    .mainSelector{
        display: flex;
        flex-direction: column;
       
    }
    .mainSelectorWrapper{
        gap: .1vw;
        height: 74px;
    }
    .mainSelectorFree{
        min-width: 0px;
        width: 30vw;
        

    }
    .mainSelectorSliderFR{
        width: 30vw;
        height: 60px;
        margin-left: -30vw;
        transform: translate(30vw,0px);


    }
    .mainSelectorSliderSB{
        width: 30vw;
        height: 60px; 
        margin-left: -30vw;
        transform: translate(60vw,0px);
    }
    .mainSelectorSliderOT{
        width: 30vw;
        height: 60px;
        margin-left: -30vw;
        transform: translate(30vw,0px);
    }
    .mainSelectorOn{
        width: 30vw;
    }
    .mainSelectorOff{
        width: 30vw;
    }

    .promoContainer{
        margin: 40px 0px 20px 0px;
        flex-direction: column;
    }
    .planTypeHeader{
        margin-right: auto;
    }


}

@media screen and (max-width: 540px) {

    .funnelSubHeader{
        width: 300px;
        text-align: center;
    }

    .card{
        max-height: fit-content;
    }
    .priceContainer{
        max-height: 150px;
    }
    .detailsContainer{
        height:auto;
    }
    

  
}