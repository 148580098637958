.remotosystemlogoIcon {
  position: relative;
  width: 130px;
  height: 22.91px;
  flex-shrink: 0;
}
.topFrame {
  align-self: stretch;
  border-bottom: 1px solid var(--mako);
  box-sizing: border-box;
  height: 50px;
  flex-shrink: 0;
  flex-direction: column;
  padding: var(--padding-3xs);
}
.textframe,
.topFrame,
.copyDictionary {
  display: flex;
  align-items: center;
  justify-content: center;
}
.copyDictionary {
  position: relative;
  font-size: 32px;
  padding: 5vh;
  width: 75vw;
  font-weight: 200;
  font-family: inherit;



 
}

.extraInfo {
  position: relative;
  font-size: 18px;
  width: 75vw;
  font-weight: 200;
  font-family: inherit;



 
}
.textframe {
  flex-direction: row;
}
.downloadIcon {
  max-width: 50%;
  overflow: hidden;
  max-height: auto;
}
.downloadInstaller,
.macos {
  margin: 0;
  position: relative;
  font-weight: 700;
  font-family: inherit;
}
.downloadInstaller {
  font-size: inherit;
  line-height: 32px;
  text-transform: capitalize;
}
.macos {
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
}
.download {
  align-self: stretch;

  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-3xs);
}
.downloadIcon1 {
  position: relative;
  width: 16.04px;
  height: 15.14px;
  flex-shrink: 0;
  
}
.icDownload {
  width: 22px;
  height: 22px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.primaryButton {
  position: relative;
  font-size: 18pt;
  line-height: 20px;
  text-transform: capitalize;
  font-family: var(--body-15-regular);
  color: #fff;
  text-align: left;
}
.bticprimary,
.icdownload {
  align-items: center;
  justify-content: center;
}
.bticprimary {
  cursor: pointer;
  border: 0;
  padding: var(--padding-4xs) var(--padding-3xs);
  background-color: transparent;
  border-radius: var(--br-5xs);
  background: linear-gradient(
    180deg,
    rgba(251, 101, 32, 0.86) 31.12%,
    rgba(251, 101, 32, 0.72) 75.75%
  );
  box-shadow: 5px -5px 10px rgba(223, 92, 29, 0.2), -5px -5px 10px #202020,
    5px 5px 10px #202020;

  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  gap: 13px;
  width: 95%;
  font-size: 18pt;
  max-width: 300px;

}
.icdownload {

  height: 24px;
  flex-shrink: 0;
  display: none;
  flex-direction: column;
}
.outlined {
  position: relative;
  font-size: var(--body-15-regular-size);
  line-height: 20px;
  text-transform: capitalize;
  font-family: var(--body-15-regular);
  color: var(--orange);
  text-align: left;
}
.btoutlined,
.frame9736 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.frame9736 {
  align-self: stretch;
  flex: 1;
  gap: 16px;
}
.btoutlined {
  cursor: pointer;
  border: 1px solid var(--orange);
  padding: var(--padding-4xs) 19px;
  background-color: rgba(251, 101, 32, 0.1);
  border-radius: var(--br-5xs);
  box-sizing: border-box;
  width: 95%;
  max-width: 290px;
}
.currentlyRemotoIs {
  flex: 1;
  position: relative;
  line-height: 20px;
}
.currentlyRemotoIsOnlyAvaiWrapper {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.footerText,
.installerCardMacos {
  display: flex;
  align-items: center;
  justify-content: center;
}
.footerText {
  align-self: stretch;
  flex-direction: row;
  font-size: 12px;
}
.installerCardMacos {
  border-radius: 10px;
  border: 2px solid var(--gun-powder);
  flex-direction: column;
  padding: 30px;
  gap: var(--gap-xl);
  width: auto;
  max-width:40vw;

}
.remotouibg1Icon {
  position: relative;
  border-radius: 12.52px;
  width: 874px;
  height: 406.32px;
  flex-shrink: 0;
  object-fit: cover;
}
.contentFrame1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  font-size: var(--title-large-40-bold-size);
}
.contentFrame {
  justify-content: flex-start;
  gap: 40px;
}
.centerFrame,
.contentFrame,
.portaluiuxDownloadLandin {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.centerFrame {
  padding: var(--padding-31xl);
  justify-content: center;
  text-align: center;
  font-size: 60px;
  color: var(--swiss-coffee);
  font-family: var(--body-15-regular);
}

.redirectMessage{
  position: absolute;
  transform: translate(-50%,0);
  left: 50%;
  z-index: 10;
  justify-content: center;
  text-align: center;
  font-size: 18px;
  color: var(--swiss-coffee);
  font-family: var(--body-15-regular);
}

.portaluiuxDownloadLandin {
  position: relative;
  background: transparent;
  width: 100%;
  
  justify-content: flex-start;
}
.containerBKG {
  position: relative;
  background-image: url('../../../public/backgrounds/center_frame.png'); /* Adjust the path based on your project structure */
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  height: fit-content; /* Set the desired height for the container */
  overflow: auto; /* Enable scrolling for content exceeding container height */
}
@media screen and (max-width: 1200px) {
  .workTogetherLike {
    font-size: var(--font-size-21xl);
    text-align: center;
    width: 80%;
  }
  .textframe {
    width: 80%;
    height: 80px;
    flex-direction: column;
    gap: var(--gap-xl);
  }
  .remotouibg1Icon {
    display: none;
    flex: 1;
    align-self: stretch;
    height: auto;
  }
  .contentFrame1 {
    gap: var(--gap-3xs);
  }
  .contentFrame {
    flex-direction: column;
  }
}
@media screen and (max-width: 960px) {
  .workTogetherLike {
    width: 80%;
  }
  .textframe {
    gap: var(--gap-xl);
  }
  .contentFrame,
  .textframe {
    flex-direction: column;
  }
}
@media screen and (max-width: 420px) {
  .workTogetherLike {
    font-size: var(--font-size-21xl);
    text-align: center;
    width: 90%;
  }
  .textframe {
    flex-direction: column;
    gap: var(--gap-xl);
  }
  .remotouibg1Icon {
    display: none;
  }

  .contentFrame1 {
    display: flex;
    
  }
  .contentFrame {
    width: 90%;
    flex-direction: column;
    gap: var(--gap-xl);
  }
  .portaluiuxDownloadLandin {
    width: auto;
    height: auto;
  }
  .debugText {
    position: absolute;
    top: 0; 
    left: 0;
    font-size: small;
    color: var(--swiss-coffee);
  }

}

