.main{
    animation: fadeIn .5s ease-in-out;

}

@media screen and (max-width: 540px) {
.main{
  width: 100vw;
}
}


@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
  }
}