.outerContainer{
  width: auto;
  height: stretch;
  max-width: 100vw;
  min-height: 100vh;
  background-color: var(--mine-shaft);
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: column;

}

.innerContainer {

  width: auto;
  height: stretch; 
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: transparent;
  justify-items: space-evenly;
  align-items: center;
  display: flex;
  color: var(--swiss-coffee);
  text-align: center;
  flex-direction: column;

  

}

.remotosystemlogoIcon {
  position: relative;
  width: 130px;
  height: 22.91px;
  flex-shrink: 0;
}
.topFrame {
  align-self: stretch;
  border-bottom: 1px solid var(--mako);
  box-sizing: border-box;
  height: 50px;
  flex-shrink: 0;
  flex-direction: column;
  padding: var(--padding-3xs);
}
.textframe,
.topFrame,
.workTogetherLike {
  display: flex;
  align-items: center;
  justify-content: center;
}
.workTogetherLike {
  margin: 0;
  position: relative;
  font-size: inherit;
  font-weight: 700;
  font-family: inherit;
  background: linear-gradient(90.07deg, #ffa277, #fb6520);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: 1234px;
  flex-shrink: 0;
  opacity: 0.8;
}
.textframe {
  flex-direction: row;
}
.downloadIcon {
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.downloadInstaller,
.macos {
  margin: 0;
  position: relative;
  font-weight: 700;
  font-family: inherit;
}
.downloadInstaller {
  font-size: inherit;
  line-height: 32px;
  text-transform: capitalize;
}
.macos {
  font-size: 17px;
  line-height: 20px;
}
.download {
  align-self: stretch;
  height: 62px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-3xs);
}
.downloadIcon1 {
  position: relative;
  width: 16.04px;
  height: 15.14px;
  flex-shrink: 0;
}
.icDownload {
  width: 22px;
  height: 22px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.primaryButton {
  position: relative;
  font-size: var(--caption-15-bold-size);
  line-height: 20px;
  text-transform: capitalize;
  font-family: var(--body-15-regular);
  color: #fff;
  text-align: left;
}
.bticprimary,
.icdownload {
  align-items: center;
  justify-content: center;
}
.bticprimary {
  cursor: pointer;
  border: 0;
  margin: 10px;
  padding: var(--padding-4xs) var(--padding-3xs);
  background-color: transparent;
  border-radius: var(--br-5xs);
  background: linear-gradient(
    180deg,
    rgba(251, 101, 32, 0.86) 31.12%,
    rgba(251, 101, 32, 0.72) 75.75%
  );
  box-shadow: 5px -5px 9px rgba(223, 92, 29, 0.2), -5px -5px 8px #202020,
    5px 5px 8px #202020;
  width: 246px;
  display: flex;
  
  flex-direction: row;
  box-sizing: border-box;
  gap: 10px;
}
.icdownload {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  display: none;
  flex-direction: column;
}
.outlined {
  position: relative;
  font-size: var(--body-15-regular-size);
  line-height: 20px;
  text-transform: capitalize;
  font-family: var(--body-15-regular);
  color: var(--orange);
  text-align: left;
}
.btoutlined,
.frame9736 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.frame9736 {
  align-self: stretch;
  flex: 1;
  gap: 16px;
}
.btoutlined {
  cursor: pointer;
  border: 1px solid var(--orange);
  padding: var(--padding-4xs) 19px;
  background-color: rgba(251, 101, 32, 0.1);
  border-radius: var(--br-5xs);
  box-sizing: border-box;
  width: 246px;
}
.currentlyRemotoIs {
  flex: 1;
  position: relative;
  line-height: 20px;
}
.currentlyRemotoIsOnlyAvaiWrapper {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.downloadButtonLabelContainer {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: space-between;

  
}
.footerText,
.installerCardMacos {
  display: flex;
  align-items: center;
  justify-content: center;
}
.footerText {
  align-self: stretch;
  flex-direction: row;
  font-size: 12px;
}
.installerCardMacos {
  border-radius: 10px;
  border: 2px solid var(--gun-powder);
  flex-direction: column;
  padding: 30px;
  gap: var(--gap-xl);
}
.remotouibg1Icon {
  position: relative;
  border-radius: 12.52px;
  width: 874px;
  height: 406.32px;
  flex-shrink: 0;
  object-fit: cover;
}
.contentFrame1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 50px;
  font-size: var(--title-large-40-bold-size);

}
.contentFrame {
  justify-content: stretch;
  gap: 100px;
  height: auto
}

.portaluiuxDownloadLandin {
  width: 100vw;
  height: 92vh;
  
  flex-direction: column;
  align-items: center;
  justify-items: space-between;

}
.centerFrame {
  padding: var(--padding-31xl);
  justify-content: center;
  text-align: center;
  font-size: 60px;
  color: var(--swiss-coffee);
  font-family: var(--body-15-regular);
  min-height: fit-content;
}
.portaluiuxDownloadLandin {
  position: relative;
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(0deg, #44444d, #000);
  width: 100%;
  overflow: hidden;
  justify-content: flex-start;
}
@media screen and (max-width: 1200px) {
  .workTogetherLike {
    font-size: var(--font-size-21xl);
    text-align: center;
    min-width: 80%;
  }
  .textframe {
    min-width: 80%;
    height: 80px;
    flex-direction: column;
    gap: var(--gap-xl);
  }
  .remotouibg1Icon {
    display: none;
    flex: 1;
    align-self: stretch;
    height: auto;
  }
  .contentFrame1 {
    gap: var(--gap-3xs);
  }
  .contentFrame {
    flex-direction: column;
  
  }
  .extraInfo{
    height: 0px; 
  }
}
@media screen and (max-width: 960px) {
  .workTogetherLike {
    font-size: var(--font-size-21xl);
    width: 80%;
  }
  .textframe {
    gap: var(--gap-xl);
  }
  .contentFrame,
  .textframe {
    flex-direction: column;
  }
}
@media screen and (max-width: 420px) {
  .workTogetherLike {
    font-size: 20px;
    text-align: center;
    width: 90%;
  }
  .textframe {
    flex-direction: column;
    gap: var(--gap-xl);
  }
  .remotouibg1Icon {
    display: none;
  }
  .contentFrame1 {
    display: flex;
  }
  .contentFrame {
    width: 90%;
    flex-direction: column;
    gap: var(--gap-xl);
  }
  .portaluiuxDownloadLandin {
    width: auto;
    height: auto;
  }
}
