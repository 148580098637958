@font-face {
  font-family: "Arial";
  src: url("/public/fonts/arial.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Arial";
  src: url("/public/fonts/arialbd.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("/public/fonts/ProximaNova-Light.ttf");
  font-weight: 300;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("/public/fonts/ProximaNova-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("/public/fonts/ProximaNova-Semibold.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("/public/fonts/ProximaNova-Bold.ttf");
  font-weight: 700;
}

body {
  font-family: var(--font-proxima-nova);
  margin: 0;
  width: 100%;
  line-height: normal;
  background-color: #202020;
}

:root {
  /* fonts */
  --body-strong-17-bold: "Proxima Nova";
  --font-proxima-nova: "Proxima Nova";
  /* font sizes */
  --body-strong-17-bold-size: 17px;
  --title-28-bold-size: 28px;
  --body-15-regular-size: 15px;
  --title-24-bold-size: 18px;
  --font-size-sm: 14px;
  --font-size-41xl: 50px;
  --font-size-21xl: 40px;
  --font-size-25xl: 44px;
  --font-size-8xl: 27px;
  --font-size-15xl: 34px;
  --font-size-161xl: 180px;

  /* Colors */
  --pure-white: #fff;
  --mako: #44444d;
  --tuna: #313139;
  --mine-30: #2c2c32;
  --manatee: #8a8a96;
  --swiss-coffee: #ddd8d8;
  --color-gainsboro-100: rgba(221, 216, 216, 0.2);
  --orange: #fb6520;
  --color-coral: #f9783c;
  --mine-shaft: #202020;
  --gun-powder: #48485c;
  --error-red: #EF4948;
  --success-green: #2DBF81;

  /* Gaps */
  --gap-3xs: 10px;
  --gap-61xl: 80px;
  --gap-base: 16px;
  --gap-9xs: 4px;
  --gap-31xl: 50px;
  --gap-11xl: 30px;
  --gap-8xl: 27px;
  --gap-41xl: 60px;
  --gap-21xl: 40px;
  --gap-131xl: 150px;
  --gap-xl: 20px;
  /* Paddings */
  --padding-4xs: 9px;
  --padding-lgi: 19px;
  --padding-81xl: 100px;
  --padding-3xs: 10px;
  --padding-321xl: 340px;
  --padding-21xl: 40px;
  --padding-xl: 20px;
  --padding-31xl: 50px;
  --padding-11xl: 30px;
  --padding-61xl: 80px;
  --padding-mini-6: 14.6px;
  --padding-base-1: 16.1px;
  --padding-7xs-8: 5.8px;
  --padding-mini-1: 14.1px;
  --padding-base-5: 15.5px;
  --padding-7xs-6: 5.6px;

  /* border radiuses */
  --br-5xs: 8px;
  --br-xl: 20px;
  --br-3xs: 10px;
  --br-mini-6: 14.6px;
  --br-mini-1: 14.1px;
  --br-9xs: 4px;

  /* Effects */
  --neumorph-button: -1px -1px 2px rgba(255, 255, 255, 0.15),
    2px 2px 13px #202020;
}


.amplify-input {
  font-size: 18pt;
  background-color: var(--gun-powder);
  border-radius: 10px;
  color: var(--swiss-coffee);
  font-family: var(--font-proxima-nova)
}

.amplify-grid {
  font-size: 18pt;
  font-family: var(--font-proxima-nova);
}

.amplify-select {
  font-size: 18pt;
  background-color: var(--gun-powder);
  border-radius: 10px;
  flex-direction: column;
  display: flex;
  color: var(--swiss-coffee);
  align-items: center;
}

.amplify-textarea {
  font-size: 18pt;
  background-color: var(--gun-powder);
  border-radius: 10px;
  color: var(--swiss-coffee);
  font-family: var(--font-proxima-nova);
}

.amplify-field {
  flex-direction: column;
  display: flex;

}

.amplify-textareafield {
  flex-direction: column;
  display: flex;
}

.amplify-field-group__field-wrapper {
  flex-direction: column;
  display: flex;
}

.amplify-select__wrapper {
  flex-direction: column;
  display: flex;
  color: var(--swiss-coffee);

}

.amplify-button {
  background-color: var(--gun-powder);
  border-radius: 10px;
  color: var(--swiss-coffee);
  width: 10vw;
  height: 5vh;
  font-size: 18px;

}

.amplify-button--primary {
  background-color: var(--orange);
  border-radius: 10px;
  color: var(--mine-shaft);
}

input {
border-radius: 9px;
border: 0px;
background: var(--Tuna, #313139);
display: flex;
padding: 10px;
align-items: flex-start;
gap: 15px;
flex: 1 0 0;
align-self: stretch;
color: var(--swiss-coffee)

}


a{
  text-decoration: underline;
  color: #FB6520;
  cursor: pointer;
}
a:visited{
  color: var(--orange)
}
select {
  background-color: var(--tuna);
  color: var(--swiss-coffee);
  text-align: left;
  padding: 10px;
  border: 0px;
  border-radius: 8px;

}

textarea {
  background-color: var(--tuna);
  border: 0px;
  border-radius: 15px;
  height: 10vh;
  color: var(--swiss-coffee);
  padding: 15px;
  color: var(--swiss-coffee)


}
p {
  margin: 0
}
h3 {
  color: var(--Swiss-Coffee, #DDD8D8);

  font-size: 24pt;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

/*adjust global scroll bar and thmb*/
::-webkit-scrollbar {
  width: 15px;
  
}

::-webkit-scrollbar-thumb {
 background: var(--mine-30);
 
}
::-webkit-scrollbar-thumb:hover {
  background-color: var(--gun-powder);
  height: 15px;
}

::-webkit-scrollbar-track {
  background-color: var(--mine-shaft);
  
}

   



h1 {

  font-weight: 300;
  width: auto;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  color: var(--swiss-coffee);
  text-align: center;
  font-weight: 100;
  font-size: var(--font-size-41xl);
  margin: auto;
}


h2 {
  color: var(--orange);
  text-align: center;
  font-weight: lighter;
  font-size: large;
}

label {
  color: #DDD8D8;

  font-family: Proxima Nova;
  font-size: 14pt;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}

button{
 
  width: stretch;
  text-align: center;
  padding: 10px;
  background-color: var(--orange);
  color: antiquewhite;
  border-radius: 6px;
  border-color: transparent;
  cursor: pointer;
}
button:hover{
  background-color: var(--manatee);

  color: #f5f5f5;
}

@media screen and (max-width: 500px) {
  ::-webkit-scrollbar {
    width: 0px;
    
  }
}

/*modify the style of the choose file dialogue for input type file*/
