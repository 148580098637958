.remotosystemlogoIcon {
  position: relative;
  width: 200px;
  height: 35.25px;
}
.div {
  position: relative;
  font-size: var(--body-15-regular-size);
  line-height: 20px;
  font-family: var(--font-proxima-nova);
  color: var(--swiss-coffee);
  text-align: center;
  
}



.menuButton1 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  align-self: stretch;
  width: 114px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.menuButton1:hover {
  background-color: var(--mine-shaft);
}
.menuButton1:active {
  background-color: var(--orange);
}
.div1 {
  position: relative;
  line-height: 20px;
}
.menuButton2 {
  align-self: stretch;
  width: 114px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.menuButton2:hover {
  background-color: var(--mine-shaft);
}
.menuButton2:active {
  background-color: var(--orange);
}
.menuButton3 {
  font-family: var(--body-strong-17-bold);
}
.menuButton3:hover {
  background-color: var(--mine-shaft);
}
.menuButton3:active {
  background-color: var(--orange);
}
.menuButton3,
.menuButton4 {
  align-self: stretch;
  width: 114px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.menuButton4:hover {
  background-color: var(--mine-shaft);
}
.menuButton4:active,
.menuButton6 {
  background-color: var(--orange);
}
.menuButton6 {
  border-radius: var(--br-5xs);
  width: 114px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--pure-white);
}
.menuButton6:hover {
  background-color: var(--color-coral);
}
.menuButton6:active {
  border: 2px solid var(--pure-white);
  box-sizing: border-box;
}
.line6Stroke {
  position: relative;
  width: 19px;
  height: 3px;
}
.icBurguermenu,
.line6StrokeParent {
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.line6StrokeParent {
  display: flex;
  gap: var(--gap-9xs);
}
.icBurguermenu {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  width: 24px;
  height: 24px;
  display: none;
}
.rightLinks {
  flex-direction: row;
  gap: var(--gap-base);
}
.rightLinks,
.topBar,
.topBarButtonsFrame {
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
}
.topBarButtonsFrame {
  flex: 1;
  flex-direction: row;
  gap: var(--gap-61xl);
}
.topBar {
  background-color: var(--tuna);
  height: 70px;
  flex-direction: column;
}
.bringingTheStudio {
  
  margin: 0;
}
.whereverYouAre {
  font-family: var(--font-proxima-nova);

}
.bringingTheStudioContainer {
  width: auto;
  z-index: 2;
  margin: 0;
  padding: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  font-family: inherit;
  transform: translate(0, -60vh);
  color: var(--swiss-coffee);
}
.topVideo,
.topVideo_Tall {
  z-index: 2;
}
.topVideo{
  height: 85vh;
  width: 100vw;
  object-fit: cover;
  position: relative;
  visibility: visible;
  justify-content: center;
  align-items: center;
  background-color: var(--tuna);
}
.topVideo_Tall{
  height: 0;
  visibility: hidden;
  width: 0;
  object-fit: cover;
  position: relative;
  
  justify-content: center;
  align-items: center;
  background-color: var(--tuna);

}


.redBluredIcon,
.remotoLinesIcon {
  position: absolute;
  margin: 0 !important;
}
.redBluredIcon {

  top: -150%;
  
  
  height: auto;
  object-fit: cover;

  width: 100vw;
  overflow: hidden;
  z-index: 0;
}
.remotoLinesIcon {

  top: 1200px;

  width: 100vw;
  height: 120vh;
  z-index: 0;

  overflow: hidden;
}
.welcomeToThe {

  font-weight: 300;
}
.welcomeToTheContainer {
  position: relative;
  top: 0; /* Adjust this as necessary to fit your design */
  padding-top: 50px;
  max-width: 750px;
  margin-top: 0px;
  margin-bottom: 20px;
  display: inline-block;
  font-size: 30pt;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 2; /* Ensure it's above the video */
  font-family: inherit;
  color: var(--swiss-coffee);

}
.welcomeText {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs);
  align-items: center;
  justify-content: center;
  position: relative;
  gap: var(--gap-3xs);
  font-size: var(--font-size-41xl);
}
.isolationModeIcon {
  position: relative;
  width: 82.01px;
  height: 62.03px;
  overflow: hidden;
  flex-shrink: 0;
}
.colaborationReImagined {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  font-weight: 700;
  font-family: inherit;
}
.remotoReimaginesAnd {
  margin: 0;
  
  position: relative;
  font-size: var(--title-24-bold-size);
  max-width: 400px;
}
.colaboration {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
  min-width: 500px;
}
.screencaptureFadeUi {
  
  position: relative;
  

  overflow: visible;
  height: 400px;
  object-fit: cover;

}

.collab {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-41xl);
  text-align: left;
  width: stretch;
 
}

.screencaptureFadeUi1 {

  position: relative;

  overflow: visible;
  height: 400px;

  object-fit: cover;
}
.vectorIcon {
  position: relative;
  width: 82.01px;
  height: auto;

}

.div6 {
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  height: 400px;
  justify-content: center;
  gap: var(--gap-xl);
}
.cuttingEdge {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-41xl);
  text-align: right;
  width: stretch;
}
.screencaptureFadeUi2 {
  
  position: relative;

  overflow: visible;
  height: 400px;

  object-fit: cover;
}
.theMostPowerful {
  margin: 0;
  flex: 1;
  position: relative;
  font-size: inherit;
  line-height: 50px;
  font-weight: 300;
  font-family: inherit;
}
.featuresFrame,
.highlightedSentence {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-21xl);
}
.featuresFrame {
  flex-direction: column;
  align-items: space-between;
  justify-content: center;
  gap: var(--gap-1xl);
  font-size: var(--font-size-15xl);
  align-self: center;
  max-width: 1500px;
  min-width: 400px;
  margin: auto; /* Center the container */
}
.whyRemoto {
  margin: 0 !important;
  align-items: stretch;
  justify-content: center;
  width: auto;
  text-align: center;
  position: relative; 
  font-size: 160px;
  letter-spacing: 0.02em;
  font-weight: 700;
  font-family: inherit;
  color: transparent; 
  -webkit-text-stroke: 2px #8a8a96;
  z-index: 2;
  
}
.screencaptureFadeUi3 {
  align-self: center;
  position: relative;
  max-width: 1000px;
  overflow: visible;
  height: auto;
  flex-shrink: 0;
  object-fit: cover;

}
.remotoDesignsOur {
  display: block;
}
.remotoDesignsOurContainer {
  margin: 0;
  flex: 1;
  position: relative;
  max-width: 1000px;
}
.textWhyRemotoFrame {
  align-self: center;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-61xl);
  align-items: center;
  justify-content: center;
  width: auto;
  max-width: 100vw;
  min-width: 80vw; 
}
.remotoUiText {
  border-radius: var(--br-3xs);
  background-color: var(--mine-shaft);
  padding: 0 0 var(--padding-11xl);
  align-items: center;

  position: relative;
  z-index: 2;
}
.remotoUiText
   {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.whyRemotoText {
  align-items: center;
  z-index: 1;
  text-align: left;
  font-size: var(--title-24-bold-size);
  color: var(--swiss-coffee);
  width: auto;
}
.whyRemotoFrame {


  align-items: center;
  position: relative;
  gap: var(--gap-3xs);
  font-size: var(--font-size-161xl);
  color: var(--mine-shaft);
  width: auto; 
  display: flex;
  flex-direction: column;

}
.today {
  color: var(--orange);
}
.scheduleADemoContainer,
.wellShowYou {
  margin: 0;
  align-self: stretch;
  position: relative;
}
.scheduleADemoContainer {
  font-size: inherit;
  font-weight: 700;
  font-family: inherit;
}
.wellShowYou {
  font-size: 24px;
  line-height: 41px;
  font-weight: 500;
}
.textFrame {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-8xl);
}
.enterYourEmail {
  position: relative;
  font-size: var(--title-28-bold-size);
  line-height: 32px;
  font-family: var(--font-proxima-nova);
  color: var(--swiss-coffee);
  text-align: center;
}
.textfield {
  align-self: stretch;
  border-radius: var(--br-xl);
  background-color: var(--color-gainsboro-100);
  border: 1px solid var(--mako);
  box-sizing: border-box;
  width: 800px;
  display: none;
  flex-direction: row;
  padding: var(--padding-xl) var(--padding-11xl);
  align-items: center;
  justify-content: space-between;
}
.bookADemo {
  position: relative;
  font-size: 24px;
  line-height: 32px;
 
  font-family: var(--font-proxima-nova);
  color: var(--white-smoke);
  text-align: center;
  cursor: pointer;
  
}
.button,
.startfreetrial {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 200px;

}

.startfreetrial {
  
  border-radius: 10px;
  border-width: 1px;
  border-color: #FD6500;
  background-color: #FD650025;
  border-style: solid;
  color : #FD6500;
  flex-direction: row;
  padding: var(--padding-xl) var(--padding-31xl);
  max-width: 1200px;
  align-self: center;
}


.startfreetrial:hover {
  color: var(--white-smoke)

}

.startfreetrial:active {
  color: var(--swiss-coffee);
  border-color: var(--swiss-coffee)
}


.frameSched,
.youremail {
  justify-content: center;
}
.youremail {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  align-self: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--gap-3xs);
}
.frameSched {
  gap: var(--gap-11xl);
  
}
.contentsFrame,
.frameSched,
.scheduleDemoFrame {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 0px 40px 0px;
}
.scheduleDemoFrame {
  
  justify-content: center;
}
.contentsFrame {
  min-height: 100vh; /* Sets the minimum height to 100% of the viewport height */
  height: fit-content;
  flex-grow: 1;
  justify-content: flex-start;
  overflow-y: overlay;
  font-size: var(--font-size-41xl);
  margin-top: 75px;
  padding-bottom: 60px;
  height: fit-content;
  max-width: 100vw;

}
.icRemotoIcon {
  position: relative;
  width: 36px;
  height: 36px;
  overflow: hidden;
  flex-shrink: 0;
}
.remotopro {
  position: relative;
  font-size: var(--body-15-regular-size);
  line-height: 20px;
  text-transform: capitalize;
  font-family: var(--body-strong-17-bold);
  color: var(--swiss-coffee);
  text-align: left;
}
.iconFrame,
.text,
.text2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.iconFrame,
.text2 {
  align-items: center;
  justify-content: center;
}
.iconFrame {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  gap: var(--gap-3xs);
}
.text2 {
  border-right: 1px solid var(--swiss-coffee);
}
.text2,
.text3 {
  cursor: pointer;
  border: 0;
  padding: 0 var(--padding-3xs);
  background-color: transparent;
}
.bottomFrame,
.footer,
.links,
.text3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.bottomFrame,
.footer {
  align-self: stretch;
  justify-content: space-between;
}
.bottomFrame {
  flex: 1;
  border-top: 1px solid var(--manatee);
  padding: 0 var(--padding-81xl);
}
.footer {
  height: 70px;
}
.rmtWebsite {
  position: relative;
  background-color: var(--mine-shaft);

  width: auto;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: center;
  font-size: var(--body-15-regular-size);
  color: var(--swiss-coffee);
  font-family: var(--font-proxima-nova);
  animation: fadeIn 1s ease-in-out; 
  /* Added properties to stretch the container */
  height: 100vh; /* Sets the minimum height to 100% of the viewport height */

}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
}

  
}
@media screen and (max-width: 1200px) {
  .icBurguermenu,
  .menuButton3,
  .menuButton4 {
    display: none;
  }
  .redBluredIcon,
  .remotoLinesIcon {
    flex: 1;
    align-self: stretch;
    height: auto;
    width: 100vw;
    overflow: hidden;

    object-fit: cover;

    
  }
  .remotoLinesIcon {
    display: flex;
    max-width: 100vw;
    overflow: hidden;

  }
  .colaboration{
    min-width: 0px;
    width: stretch;
  }
  .collab, .cuttingEdge{
    align-items: center;
  }
  .featuresFrame {
    padding-left: var(--padding-81xl);
    padding-right: var(--padding-81xl);
    box-sizing: border-box;
    min-width: 85vw;
    width: stretch;
  }

  .screencaptureFadeUi3 {
    flex: 1;
    align-self: stretch;
    height: auto;
  }
  .scheduleDemoFrame,
  .whyRemotoFrame {
    padding-left: var(--padding-81xl);
    padding-right: var(--padding-81xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 960px) {
  .menuButton1,
  .menuButton2,
  .menuButton3,
  .menuButton4 {
    display: none;
  }
  .icBurguermenu,
  .menuButton6,
  .rightLinks {
    display: flex;
  }
  .redBluredIcon {
    display: none;
    width: 100vw;
    overflow: hidden;
  }
  .remotoLinesIcon {
    display: flex;
    flex: 1;
    align-self: stretch;
    height: auto;
    max-width: 100vw;
    overflow: hidden;

  }
  .colaboration,
  .screencaptureFadeUi {
    flex: unset;
    align-self: stretch;
  }
  .collab {
    flex-direction: column;
  }
  .div6,
  .screencaptureFadeUi1 {
    flex: unset;
    align-self: stretch;
  }
  .cuttingEdge {
    flex-direction: column-reverse;
  }
  .screencaptureFadeUi2 {
    flex: unset;
    align-self: stretch;
  }
  .whyRemoto {
    flex: 1;
    font-size: 60px;
    
  }
  .rmtWebsite {
    width: auto;
    align-self: unset;
  }
  .screencaptureFadeUi3{
    width: 100vw;
  }
  .textWhyRemotoFrame{
    padding-left: 20px;
    padding-right: 20px;
  }
  .featuresFrame {
    width: auto;
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media screen and (max-width: 540px) {
  .topVideo, .videoContainer {
    height: 60vh;
  }
  .featuresFrame{
    width: 100vw;
    padding: 80px 0px 0px 0px;
    gap: 80px;
  }

  .menuButton6 {
    display: none;
  }
  .icBurguermenu {
    display: flex;
  }
  .topVideo_Tall{
    height: auto;
    width: 100vw;
    object-fit: cover;
    position: relative;
    visibility: visible;
    justify-content: center;
    align-items: center;

    background-color: transparent;
  }
  .topVideo{
    height: 0;
    visibility: hidden;
    width: 0;
    object-fit: cover;
    position: relative;
    
    justify-content: center;
    align-items: center;
    background-color: var(--tuna);
  
  }
  .remotoReimaginesAnd {
    width: 80vw;
    align-self: left;
    text-align: left;
    font-size: var(--font-size-sm);

  }
  .bringingTheStudioContainer {

    font-size: var(--font-size-21xl);
  }
  .redBluredIcon {
    display: none;
    
    min-width: 420px;
    width: 100vw;
    overflow: hidden;
  }
  .remotoLinesIcon {
    display: flex;
    max-width: 100vw;
    overflow: hidden;

  }
  .welcomeToTheContainer {
    font-size: var(--font-size-21xl);
    text-align: center;
    padding: 20px 20px 20px 20px;
    
  }
  .welcomeText {
    display: flex;
    flex-direction: row;
  }
  .colaborationReImagined {
    font-size: 24px;
    align-self: center;
  
  }
  .collab, .cuttingEdge {
    align-items: center;
    width: 100vw;
    gap: 10px;
  }
  .div6,
 .colaboration {
    height: auto;
    justify-content: center;
    display: flex;
    width: 100vw;
    min-width: 0px;
    align-items: center;
  }
  .screencaptureFadeUi,
  .screencaptureFadeUi1, 
  .screencaptureFadeUi2 {
    align-self: center;
    height: 50%;
    width: 50%;
  }
  .theMostPowerful {
    font-size: 36px;
    line-height: 40px;
    text-align: left;
  }
  .whyRemoto {
    align-self: center;
    width: auto;
    font-size: 30%;
  }
  .screencaptureFadeUi3 {
    min-width: 300px;
    min-height: 72px;
  }
  .remotoDesignsOurContainer {
    font-size: var(--font-size-sm);
  }
  .textWhyRemotoFrame,
  .whyRemotoFrame {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
  .textWhyRemotoFrame {
    flex-direction: row;
  }
  .whyRemotoFrame {
    flex: 1;
  }
  .scheduleADemoContainer {
    font-size: var(--font-size-21xl);
  }
  .wellShowYou {
    font-size: var(--title-24-bold-size);
  }

  .bottomFrame,
  .scheduleDemoFrame {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
  .footer {
    align-items: center;
    justify-content: center;
  }
}
