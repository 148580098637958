.portaluiuxCalendlyLandin {
  position: relative;
  width: auto;
  height: 100vh; 
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-image: url(/public/backgrounds/portaluiux-calendly-landing-page-for-remoto-app-remt2738---desktop@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
@media screen and (max-width: 960px) {
  .portaluiuxCalendlyLandin {
    height: auto;
  }
}
@media screen and (max-width: 420px) {
  .portaluiuxCalendlyLandin {
    width: auto;
    height: auto;
  }
}


