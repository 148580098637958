/* Extra small screen / phone */
$mobile-screen-min: 375px;
$mobile-screen-max: 767px;

/* Tablet portrait screen / tablet */
$tablet-screen-min: 768px;
$tablet-screen-max: 1023px;

/* Medium screen / desktop */
$md-screen-min: 1024px;
$md-screen-max: 1439px;

/* Large screen / wide desktop */
$lg-screen-min: 1440px;
$lg-screen-max: 1920px;

/* mixin for mobiles and tablets from 375px to 768 */
@mixin mobile {
  @media (min-width: #{$mobile-screen-min}) and (max-width: #{$mobile-screen-max}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-screen-min}) and (max-width: #{$tablet-screen-max}) {
    @content;
  }
}

@mixin desktop-md {
  @media (min-width: #{$md-screen-min}) and (max-width: #{$md-screen-max}) {
    @content;
  }
}

@mixin screen-max {
  @media screen and (min-width: #{$lg-screen-min}) and (max-width: #{$lg-screen-max}) {
    @content;
  }
}

////// global.css file

@font-face {
  font-family: "Arial";
  src: url("../public/fonts/arial.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Arial";
  src: url("../public/fonts/arialbd.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("../public/fonts/ProximaNova-Light.ttf");
  font-weight: 300;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../public/fonts/ProximaNova-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../public/fonts/ProximaNova-Semibold.ttf");
  font-weight: 500;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../public/fonts/ProximaNova-Bold.ttf");
  font-weight: 700;
}

body {
  font-family: var(--font-proxima-nova);
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --body-strong-17-bold: "Proxima Nova";
  --font-proxima-nova: "Proxima Nova";
  /* font sizes */
  --body-strong-17-bold-size: 17px;
  --title-28-bold-size: 28px;
  --body-15-regular-size: 15px;
  --title-24-bold-size: 24px;
  --font-size-sm: 14px;
  --font-size-41xl: 60px;
  --font-size-21xl: 40px;
  --font-size-25xl: 44px;
  --font-size-8xl: 27px;
  --font-size-15xl: 34px;
  --font-size-161xl: 180px;

  /* Colors */
  --pure-white: #fff;
  --mako: #44444d;
  --tuna: #313139;
  --mine-30: #2c2c32;
  --manatee: #8a8a96;
  --swiss-coffee: #ddd8d8;
  --color-gainsboro-100: rgba(221, 216, 216, 0.2);
  --orange: #fb6520;
  --color-coral: #f9783c;
  --mine-shaft: #202020;
  --gun-powder: #48485c;

  /* Gaps */
  --gap-3xs: 10px;
  --gap-61xl: 80px;
  --gap-base: 16px;
  --gap-9xs: 4px;
  --gap-31xl: 50px;
  --gap-11xl: 30px;
  --gap-8xl: 27px;
  --gap-41xl: 60px;
  --gap-21xl: 40px;
  --gap-131xl: 150px;
  --gap-xl: 20px;
  /* Paddings */
  --padding-4xs: 9px;
  --padding-lgi: 19px;
  --padding-81xl: 100px;
  --padding-3xs: 10px;
  --padding-321xl: 340px;
  --padding-21xl: 40px;
  --padding-xl: 20px;
  --padding-31xl: 50px;
  --padding-11xl: 30px;
  --padding-61xl: 80px;
  --padding-mini-6: 14.6px;
  --padding-base-1: 16.1px;
  --padding-7xs-8: 5.8px;
  --padding-mini-1: 14.1px;
  --padding-base-5: 15.5px;
  --padding-7xs-6: 5.6px;

  /* border radiuses */
  --br-5xs: 8px;
  --br-xl: 20px;
  --br-3xs: 10px;
  --br-mini-6: 14.6px;
  --br-mini-1: 14.1px;
  --br-9xs: 4px;

  /* Effects */
  --neumorph-button: -1px -1px 2px rgba(255, 255, 255, 0.15),
    2px 2px 13px #202020;
}

.amplify-input {
  font-size: 18pt;
  background-color: var(--gun-powder);
  border-radius: 10px;
  color: var(--swiss-coffee);
  font-family: var(--font-proxima-nova);
}
.amplify-grid {
  font-size: 18pt;
  font-family: var(--font-proxima-nova);
}

.amplify-select {
  font-size: 18pt;
  background-color: var(--gun-powder);
  border-radius: 10px;
  flex-direction: column;
  display: flex;
  color: var(--swiss-coffee);
  align-items: center;
}
.amplify-textarea {
  font-size: 18pt;
  background-color: var(--gun-powder);
  border-radius: 10px;
  color: var(--swiss-coffee);
  font-family: var(--font-proxima-nova);
}
.amplify-field {
  flex-direction: column;
  display: flex;
}
.amplify-textareafield {
  flex-direction: column;
  display: flex;
}
.amplify-field-group__field-wrapper {
  flex-direction: column;
  display: flex;
}
.amplify-select__wrapper {
  flex-direction: column;
  display: flex;
  color: var(--swiss-coffee);
}
.amplify-button {
  background-color: var(--gun-powder);
  border-radius: 10px;
  color: var(--swiss-coffee);
  width: 10vw;
  height: 5vh;
  font-size: 18px;
}
.amplify-button--primary {
  background-color: var(--orange);
  border-radius: 10px;
  color: var(--mine-shaft);
}
