.carousel-container {
    position: relative;
    margin: auto;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: auto;
    background-color: var(--mine-shaft);
    border-radius: 10px;
  }
  
  .video-wrapper iframe {
    border-radius: 10px;
    animation : fadeIn .5s ease-in;
    
    width: 530px;
    height: 300px; /* Adjust according to your needs */
  }

    @keyframes fadeIn {
        from {
        opacity: .5;
        }
        to {
        opacity: 1;
        }
    }
  
  .arrows button {
    position: absolute;
    top: 100%;
    transform: translateY(-100%);
    border: none;
    cursor: pointer;
    font-size: 20px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: -4px 4px 4px 4px;
  }
  
  
  .arrows button:first-child {
    left: 0%;
  }
  
  .arrows button:last-child {
    right: 0%;

  }
  
  .dots {
    text-align: center;
    padding-top: 10px;
  }
  
  .dot {
    cursor: pointer;
    height: 15px;
    width: 15px;
    margin: 0 5px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
    
  }
  
  .active, .dot:hover {
    background-color: #FB6520;
    animation: fadeIn .5s ease-in;
  }
  
  @media screen and (max-width: 540px) {
    .video-wrapper iframe {
      width: 100%;
      height: 200px;
    }

  }