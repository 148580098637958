.mainForm {
  color: var(--manatee);
  display: flex;
  flex-direction: column;
  gap: 10px;
  
  width: 40vw;
  min-width: 400px;
  max-width: 600px;
  background-color: var(--mine-shaft);
  padding: 50px;
  border-radius: 16px;
  box-shadow: 2px 2px 10px rgba(8, 8, 8, 0.519), -2px -2px 10px rgba(80, 80, 80, 0.237);

}

.horizontalContainer {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  width: 250px;


}
.formCard{
  display: flex; 
  width: 100%; 
  justify-content: center; 
  margin: 20px;
}

.checkboxText{
  color: var(--manatee);
  width: auto;
  font-size: 12pt;
}

.submitButton {
  max-width: 200px;
  
  margin: 10px auto; /* Updated line */
  opacity: 100;
  background: linear-gradient(#ff8045, #d94500);
  box-shadow: 2px 2px 10px rgba(8, 8, 8, 0.862), -2px -2px 10px rgba(80, 80, 80, 0.798);
  background-color: transparent;
  cursor: pointer;
  border: solid 1px transparent;
}


.submitButton:hover {
  opacity: 100;
  border: 1px solid var(--orange);
  background: linear-gradient(#ff8045, #d94500);

  

}
.submitButton:active{
  box-shadow: inset -2px -2px 10px rgba(80, 80, 80, 0.257), inset 2px 2px 10px rgba(8, 8, 8, 0.559);
  background: linear-gradient(#962f00, var(--orange));

}

.submitButton:disabled {
  opacity: 40;
  background: linear-gradient(#ff80457c, #d9450072);
  cursor: not-allowed;
  
}

.gridContainer {
  display: grid;
  grid-template-columns: auto auto;
  border-radius: 15px;
  margin-top: 4px;
  gap: 5px;
  margin-bottom: 10px;

}

.formItemContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 10px;
  width: 100%;
  justify-content: center;
}
.contactHeader{
  width: 800px;
  font-size: 40px;
}

.formContainer {
  display: flex;
  width: 50vw;
  
  align-items: center;
  padding: 50px 0 50px 0;
  justify-content: center;

}

/* ContactForms.module.css */

.customCheckbox {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: var(--mako);
  border-radius: 100px;
  position: relative;
  cursor: pointer;
  margin: 10px;
  font-size: x-small;
}

.customFileUpload{
  background-color: #fb652055;
  border: 1px solid var(--orange);
  width: 200px;
  align-self: center;
  margin-top: 20px;
}


.customCheckbox input {
  opacity: 0;
  width: 0;
  height: 0;
}

.customCheckbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: transparent;
  box-shadow: 2px 2px 3px rgba(67, 67, 67, 0.137), -2px -2px 3px #00000080;

  border-radius: 100px;
}

.customCheckbox input:checked~.checkmark {
  background-color: transparent;
}

.customCheckbox .checkmark:after {
  content: "";

  display: none;
}

.customCheckbox input:checked~.checkmark:after {
  display: block;
}

.customCheckbox .checkmark:after {

  width: 10px;
  height: 10px;
  border-radius: 100px;
  background-color: var(--orange);
  border: 5px solid var(--tuna)
}

.hoverContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0px;
  /* or block, depending on your layout */
}

.formFieldIn {
  background-color: #31313980;
  box-shadow: inset 2px 2px 10px rgba(9, 9, 9, 0.653), inset -2px -2px 10px rgba(115, 115, 115, 0.266);
  max-width: 500px

}

.formFieldOut {
  -webkit-appearance: none;
  /* Removes default styling for Webkit browsers like Chrome */
  -moz-appearance: none;
  /* Removes default styling for Firefox */
  appearance: none;
  background-color: #31313980;
  box-shadow: 2px 2px 10px rgba(8, 8, 8, 0.501), -2px -2px 10px rgba(72, 72, 72, 0.354);
  background-image: url('../../../public/ic_dropdown.png');
  /* Custom arrow icon */
  background-repeat: no-repeat;
  background-size: 2%;
  background-position: right 15px center;
  /* Adjust this as needed */
  padding-right: 30px;
  color: #8A8A96;
  /* Add padding to ensure text doesn't overlap the arrow icon */

  /* Other styling for your select element */



}


.hoverBox {
  position: absolute;
  bottom: 55%;
  /* positions the box above the text */
  left: 70%;
  /* centers the box */
  width: 400px;
  /* or any desired width */
  padding: 20px;
  background-color: var(--manatee);

  font-size: large;
  color: var(--mine-shaft);

  border-radius: 8px;
  display: block;
  /* hide initially */
  z-index: 10;
  /* ensures it's above other content */
  transition: opacity 0.5s;
  /* smooth transition animation */

}

.hoverBoxOff {
  position: absolute;


  opacity: 0;
  transition: opacity 0s;
  /* smooth transition animation */


}

.hoverIconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  width: 20px;
  height: 20px;
  color: var(--mine-shaft);
  border-radius: 200px;
  box-shadow: 1px 1px 1px 0 rgba(255, 255, 255, 0.15), -1px -1px 1px 0 #202020;
  background-color: var(--manatee);
}

.qCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  width: 20px;
  height: 20px;
  color: var(--mine-shaft);
  border-radius: 200px;
  box-shadow: 1px 1px 1px 0 rgba(255, 255, 255, 0.15), -1px -1px 1px 0 #202020;
  background-color: var(--manatee);

}

.formItem {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  margin: 10px;
  padding: 10px;
  justify-content: center
}

.formError::before {
  content: "";
  display: inline-block;
  background-image: url("../../../public/icons/Failure_Red_REMT.png");
  background-size: 20px 20px;
  /* Adjust as needed */
  width: 20px;
  height: 20px;
}

.formError {
  color: var(--orange);
  font-size: small;
  display: flex;

  flex-direction: row;
  gap: 5px;
  align-items: center;
  justify-content: start;
  width: 100%;

}
.contactSubHeader{

      font-size: 17px;
      font-weight: normal;
      color: '#8A8A96';

}
@media screen and (max-width: 540px){
  .formContainer{
    width: 100vw;
    align-self: center;
    padding: 0px 20px 0px 20px;
  }
  .contactHeader{
    width: 100vw;
    font-size: 36px;
  }
  .contactSubHeader{
    width: 85vw;
  }
  .formCard{
    align-self: center;
    align-items: center;
    max-width: 90vw;
  }
  .formCard, .mainForm{
    width: 100vw;
    padding: 0px;
    margin: 0px;
   
  }
  .mainForm{
   padding: 20px;
    min-width: 80vw;
  }

}