.remotosystemlogoIcon {
  position: relative;
  width: 130px;
  height: 22.91px;
  flex-shrink: 0;
}
.topFrame {
  align-self: stretch;
  border-bottom: 1px solid var(--mako);
  box-sizing: border-box;
  height: 50px;
  flex-shrink: 0;
  flex-direction: column;
  padding: var(--padding-3xs);
}

.textframe,
.topFrame,
.copyDictionary {
  display: flex;
  align-items: center;
  justify-content: center;
}
.copyDictionary {
  position: relative;
  font-size: 32px;
  padding: 5vh;
  width: 75vw;
  font-weight: 200;
  font-family: inherit;



 
}

.headerImage {

  display: flex;
  height: 500px;


 }


 .resultHeader{
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  
 }

 .resultMessage{
  font-size: 26px;
  line-height: 31.2px;
  font-weight: 400;
 }
 .contentFrame{
  display: flex;
  flex-direction: row;
  justify-content: space-around;

 }

.extraInfo {
  position: relative;
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
  gap: 2vh; 
 
  width: auto; 
  height: auto;
  padding: 40px;
  
 
}
.imageCaption{
  padding-bottom: 10vh;
  font-size: 36pt;

    
}
.textframe {
  flex-direction: row;
}
.listTitle{
  font-size: 17px; 
  font-weight: bold;
  text-align: left;
}
.listElements{


    font-size: 14px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;


    padding-inline-start: 20px;

  

}
.downloadIcon2 {
  width: 100px;
  overflow: hidden;
  max-height: auto;
}
.logoIcon {
  width: 30vw;
  max-width: 600px;
  overflow: hidden;
  max-height: auto;
  margin-bottom: 40px;
}
.downloadInstaller,
.macos {
  margin: 0;
  position: relative;
  font-weight: 700;
  font-family: inherit;
}
.downloadInstaller {
  font-size: inherit;
  line-height: 32px;
  text-transform: capitalize;
}
.macos {
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
}
.download {
  align-self: stretch;

  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-3xs);
}
.downloadIcon1 {
  position: relative;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  
}
.icDownload {
 
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.primaryButton {
  position: relative;
  font-size: 14pt;
  align-self: center;
  justify-self: center;
 
  font-weight: normal;
  
  text-transform: capitalize;
  
  font-family: var(--body-15-regular);
  color: #fff;
  text-align: left;
}

.bticprimary,
.icdownload {
  align-items: center;
  justify-content: center;
}
.bticprimary {
  cursor: pointer;
  border: 0;
  padding: var(--padding-4xs) var(--padding-3xs);
  background-color: transparent;
  border-radius: var(--br-5xs);
  background: linear-gradient(to bottom, #FB6520DB, #FB6520B8);
  box-shadow: -2px -2px 10px rgba(255, 255, 255, 0.222), 2px 0px 10px rgba(0, 0, 0, 0.68);  


  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  gap: 13px;
  width: 90%;
  align-self: center;

}
.icdownload {

  height: 24px;
  flex-shrink: 0;
  display: none;
  flex-direction: column;
}
.outlined {
  position: relative;
  font-size: var(--body-15-regular-size);
  line-height: 20px;
  text-transform: capitalize;
  font-family: var(--body-15-regular);
  color: var(--orange);
  text-align: left;
}
.btoutlined,
.frame9736 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.frame9736 {
  align-self: stretch;
  flex: 1;
  gap: 16px;
}
.btoutlined {
  cursor: pointer;
  border: 1px solid var(--orange);
  padding: var(--padding-4xs) 19px;
  background-color: rgba(251, 101, 32, 0.1);
  border-radius: var(--br-5xs);
  box-sizing: border-box;
  width: 100%;
}
.currentlyRemotoIs {
  flex: 1;
  position: relative;
  line-height: 20px;
}
.currentlyRemotoIsOnlyAvaiWrapper {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.footerText,
.installerCardMacos {
  display: flex;
  align-items: center;
  justify-content: center;
}
.footerText {
  align-self: stretch;
  flex-direction: row;
  font-size: 12px;
}
.installerCardMacos {
  justify-content: flex-start;
  border-radius: 10px;
  border: 2px solid var(--gun-powder);
  flex-direction: column;
  padding: 30px 15px 0 15px;
  gap: var(--gap-xl);
  width: 350px;
  margin-left: 1vw; 
  margin-right: 1vw;

}
.remotouibg1Icon {
  position: relative;
  border-radius: 12.52px;
  width: 874px;
  height: 406.32px;
  flex-shrink: 0;
  object-fit: cover;
}
.contentFrame1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  font-size: var(--title-large-40-bold-size);
}

.centerFrame,
.portaluiuxDownloadLandin {
  width: auto;
  height: fit-content;
  display: flex;
  flex-direction: column;

}

.linkText{
  font-size: 12px;
  color: var(--manatee);
  display: flex;
  flex-direction: row;
  gap: 5px;
  padding: 0px 0px 20px 0px;
}
.centerFrame {
  

  
 
  flex-direction: row;
  margin-bottom: auto;
  text-align: center;
  font-size: 60px;
  width: stretch;
  color: var(--swiss-coffee);
  font-family: var(--body-15-regular);
  display: flex;

  height: stretch; 
  min-height: fit-content;
  
  margin: 0px;

  
  justify-content: center;
  gap: 50px;
  align-items: flex-start;
}
.portaluiuxDownloadLandin {
  position: relative;
  background: transparent;
  width: 100%;
  height: stretch;
  overflow: wrap;
  justify-content: flex-start;
}
.headerText{
  font-weight: 300;
  font-size: 36px;
  margin: 50px 0px 50px 0px; 
  align-self: center;
  text-align: center;
}
.containerBKG {
  flex-direction: column;
  position: relative;
  background-color: var(--mine-shaft);
  height: fit-content;
  min-height: 100vh; 
  padding: 0 0 20px 0;
  overflow: visible;
  align-items: center;
  align-self: center;
  justify-content: center;
  display: flex;
  padding-top: 75px;

}
@media screen and (max-width: 1200px) {
  .workTogetherLike {
    font-size: var(--font-size-21xl);
    text-align: center;
    width: 80%;
  }
  .textframe {
    width: 80%;
    height: 80px;
    flex-direction: column;
    gap: var(--gap-xl);
  }
  .remotouibg1Icon {
    display: none;
    flex: 1;
    align-self: stretch;
    height: auto;
  }
  .centerFrame{
    flex-direction: column;
    align-items: center;
  }
  .contentFrame1 {
    gap: var(--gap-3xs);
  }
  .contentFrame {
    flex-direction: column;

  }
  .headerImage{
    display: none;

  }
  .extraInfo{
    height: 0;
    margin: 0;
    padding: 0;
  }
  .logoIcon{
    visibility: hidden;
  }
 
}
@media screen and (max-width: 960px) {
  .workTogetherLike {
    width: 80%;
  }
  .textframe {
    gap: var(--gap-xl);
  }
  .contentFrame,
  .textframe {
    flex-direction: column;

  }
  
}

@media screen and (max-width: 750px) {
  .contentFrame1 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }


}
@media screen and (max-width: 420px) {
 
  .workTogetherLike {
    font-size: var(--font-size-21xl);
    text-align: center;
    width: 90%;
  }
  .textframe {
    flex-direction: column;
    gap: var(--gap-xl);
  }
  .remotouibg1Icon {
    display: none;
  }

  .contentFrame {
    width: 90%;
    flex-direction: column;
    gap: var(--gap-xl);
  }
  .portaluiuxDownloadLandin {
    width: auto;
    height: auto;
  }
  .debugText {
    position: absolute;
    top: 0; 
    left: 0;
    font-size: small;
    color: var(--swiss-coffee);
  }
  .containerBKG {
    height: 100vh;
  }

}

