.mainContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: stretch;
    height: auto;
    background-color: var(--mine-shaft);
    color: var(--swiss-coffee);
   
    
}
.loginCard{
    background-color: var(--tuna);
    padding: 30px 40px 20px 40px;
    border-radius: 10px;
   
    width: 500px;
    box-shadow: 1px 1px 10px rgb(8, 8, 8), -1px -1px 10px rgba(220, 220, 220, 0.151);
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    
}

.logoImage{
    width: 100%;
    max-width: 300px;
    height: auto;
    margin-bottom: 20px;

    
    padding: 0;
}
.inputContainer{
    width: 400px;
    background-color: var(--tuna);
    box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.60) inset, -2px -2px 3px 0px #48485C inset;
    height: 40px;
    margin: 5px 0px 20px 0px;
}

.formContainer{
    display: flex;
    flex-direction: column;
    width: auto;
}
.actionButton{
    background: linear-gradient(180deg, #fb6520e7 0%,  #fb6520c8 100%);
    box-shadow: -2px -2px 2px rgba(251, 101, 32, 0.10), 5px 5px 5px #202020;
    border-radius: 10px;
    text-align: center;
    width: fit-content;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 14px;
    line-height: 20px;

}
.cancelButton{
  background: var(--tuna);
  box-shadow: -1px -1px 2px rgba(255, 255, 255, 0.15), 2px 2px 13px #202020;
  border-radius: 10px;
  text-align: center;
  width: auto;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 14px;
  line-height: 20px;

}

.hzInputLabel{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    flex-direction: row;

}
.hzLabelContainer{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 10px;
    flex-direction: row;
    padding: 0px 0px 0px 0px;
    width: 100%;
    font-size: 12px;
    
  
    
    
}
.checkboxContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    
    flex-direction: row;
    font-size: 12px;
    width: stretch;
    padding: 10px 0px 20px 0px;
}

.checkboxOff{
  box-shadow: -2px -2px 10px rgba(255, 255, 255, 0.118), 2px 2px 8px #00000072;

}
.checkboxOn{
 box-shadow: -4px -4px 8px rgba(0, 0, 0, 0.3), inset -2px -2px 5px #6d6d6d28;

}
.customCheckbox{
  margin-right: 20px;
}
.hzNetworkLabel{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    flex-direction: row;
    font-size: 12px;
    width: stretch;
    padding: 10px 0px 20px 0px;

}
.escapeLabel{
    font-size: small;
}
.escapeButton{
    background-color: transparent;
    border: none;
    color: var(--swiss-coffee);
    font-size: small;
    cursor: pointer;
    text-decoration: underline;
    width: fit-content;

}
.termsFrame{
    background-color: #212121;
    display: flex;
    flex-direction: column;
    align-items: center;
    
 


    width: 100%;
    height: 80vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.subtitle-rf-16 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18.4px;
    letter-spacing: 0.5px; /* Additional letter spacing */
    color: var(--subtitle-text-color); /* Using the default subtitle text color */
  }
  
  .subtitle-20-reg {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400; /* Regular */
    line-height: 23px; /* 115% */
    color: var(--subtitle-text-color); /* Text color controlled via CSS variable */
  }
  
  .title-36-reg {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 400; /* Regular */
    line-height: 40px; /* 115% */
    color: var(--subtitle-text-color); /* Text color controlled via CSS variable */
  }
  
  .body-rf-12 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400; /* Regular */
    line-height: normal; /* Default line height */
    color: var(--body-text-color); /* Text color controlled via CSS variable */
  }
  
  .body-14-rf {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400; /* Regular */
    line-height: normal; /* Default line height */
    color: var(--body-14-rf-text-color); /* Text color controlled via CSS variable */
  }
  
  .body-15-regular {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400; /* Regular */
    line-height: 20px; /* 133.333% */
    color: var(--body-15-text-color); /* Text color controlled via CSS variable */
  }
  
  .body-large-17-regular {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 400; /* Regular */
    line-height: 20px; /* 117.647% */
    color: var(--body-large-text-color); /* Text color controlled via CSS variable */
  }
  
  .body-large-17-bold {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 700; /* Bold */
    line-height: 20px; /* 117.647% */
    color: var(--body-large-bold-text-color); /* Text color controlled via CSS variable */
  }
  
  .title-20-rf {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400; /* Regular */
    line-height: normal; /* Default line height */
    color: var(--title-20-rf-text-color); /* Text color controlled via CSS variable */
  }
  
  .title-20-bold {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700; /* Bold */
    line-height: 23px; /* 115% */
    color: var(--title-20-bold-text-color); /* Text color controlled via CSS variable */
  }
  
  .title-24-rf {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400; /* Regular */
    line-height: normal;
    color: var(--title-24-rf-text-color); /* Text color controlled via CSS variable */
  }
  
  .title-large-28-regular {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 400; /* Regular */
    line-height: 32px; /* 114.286% */
    color: var(--title-large-text-color); /* Text color controlled via CSS variable */
  }
  
  .title-28-bold {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 700; /* Bold */
    line-height: 32px; /* 114.286% */
    color: var(--title-text-color); /* Text color controlled via CSS variable */
  }
  
  .title-30-rf {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 700; /* Bold */
    line-height: normal; /* Default line height */
    color: var(--title-30-rf-text-color); /* Text color controlled via CSS variable */
  }
  
  .title-session-settings {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 400; /* Regular */
    line-height: 23px; /* 82.143% */
    color: var(--title-session-settings-text-color); /* Text color controlled via CSS variable */
  }
  
  .caption-15-bold {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 700; /* Bold */
    line-height: 20px; /* 133.333% */
    color: var(--caption-text-color); /* Text color controlled via CSS variable */
  }
  
  .subtitle-session {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700; /* Bold */
    line-height: 23px; /* 143.75% */
    color: var(--subtitle-session-text-color); /* Text color controlled via CSS variable */
  }
  
  .reg-title-24 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400; /* Regular */
    line-height: 33px; /* 137.5% */
    color: var(--reg-title-24-text-color); /* Text color controlled via CSS variable */
  }
  
  .reg-title-28 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 400; /* Regular */
    line-height: 33px; /* 117.857% */
    color: var(--reg-title-text-color); /* Text color controlled via CSS variable */
  }
  
  .footnote-10-rf {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400; /* Regular */
    line-height: normal; /* Default line height */
    color: var(--footnote-10-rf-text-color); /* Text color controlled via CSS variable */
  }
  
  .error-message-below-text-fields {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400; /* Regular */
    line-height: 20px; /* 200% */
    color: var(--error-message-text-color); /* Text color controlled via CSS variable */
  }
  
  .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10; /* Ensure it's above other content */
    background-color: rgba(0, 0, 0, 0); /* Optional: for dimmed background */
  }
  
  .footnotes-15-regular {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400; /* Regular */
    line-height: 20px; /* 133.333% */
    color: var(--footnotes-text-color); /* Text color controlled via CSS variable */
  }
  
  .modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .additional-text {
    margin-top: 30px; /* Adjust as needed for space below the modal */
    text-align: center;
  }
  
  .email-input::placeholder {
    font-style: italic; /* Make the placeholder text italicized */
    color: rgba(221, 216, 216, 0.2); /* Swiss Coffee with 50% transparency */
  }
  
  /* For full browser support, include the following vendor-specific pseudo-elements */
  .email-input:-ms-input-placeholder {
    font-style: italic;
    color: rgba(221, 216, 216, 0.2);
  }
  
  .email-input::-ms-input-placeholder {
    font-style: italic;
    color: rgba(221, 216, 216, 0.2);
  }
  
  input::placeholder {
    color: rgba(255, 255, 255, 0.2); /* White color with 50% opacity */
    font-size: 17px;
  }
  
  .login-container {
    position: relative;
    /* Make sure to give it enough padding or margin on the right to accommodate the policy menu */
  }
  
  .password-policy-container {
    position: absolute;
    right: -70%;      /* Adjust as needed */
    top: 90%;      /* Adjust as needed, this will center it vertically */
    transform: translateY(-20%); /* Centers the div based on its own height */
    width: 300px;  /* Width of the password policy menu */
    background: #fff; /* Background color for the policy menu */
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2); /* Optional: to make it pop out a bit */
    border-radius: 4px; /* Optional: if you want rounded corners */
    z-index: 100; /* Ensure it's above other content */
  }
  
  .success-message {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    color: var(--Swiss-Coffee); /* Text color using Swiss Coffee */
    background-color: var(--Jungle-Green); /* Background color using Jungle Green */
    padding: 10px; /* Added for spacing, adjust as needed */
    border-radius: 4px; /* Rounded corners, adjust as needed */
    margin-top: 10px; /* Margin for spacing, adjust as needed */
    font-weight: bold; /* Bold text, adjust as needed */
    text-align: center; /* Center the text */
  }
  
  .loading-dots {
    display: inline-block;
    margin-left: 5px;
    font-size: 20px;
    line-height: 20px;
    vertical-align: top;
    animation: dots 1.5s infinite;
  }
  
  @keyframes dots {
    0%, 20% {
      color: rgba(0,0,0,0);
      text-shadow:
        .25em 0 0 rgba(0,0,0,0),
        .5em 0 0 rgba(0,0,0,0);
    }
    40% {
      color: black;
      text-shadow:
        .25em 0 0 rgba(0,0,0,0),
        .5em 0 0 rgba(0,0,0,0);
    }
    60% {
      text-shadow:
        .25em 0 0 black,
        .5em 0 0 rgba(0,0,0,0);
    }
    80%, 100% {
      text-shadow:
        .25em 0 0 black,
        .5em 0 0 black;
    }
  }
  
  .focused {
    border: 1px solid var(--Swiss-Coffee);
  }
  .errorContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
 
    width: 100%;
    height: fit-content;
  }
  
    .errorText{
    position: relative;
    background-color: var(--mine-shaft);
    padding: 10px;
    align-self: stretch;
    
    justify-content: start;
    border-radius: 8px;
    color: var(--error-red);
    display: flex;
    height: fit-content;
    width: stretch;
    z-index: 1;
    margin: 0px 0px 20px 0px;
    border: 1px solid var(--error-red);
    font-size: 12px;
  }

  .errorText::before {
    content: "";
    display: flex;
    align-self: center;
    margin: 0px 0px 0px 0px;
    padding: 0 0 0 10px;
    background-image: url("../../../public/icons/Failure_Red_REMT.png");
    background-size: 15px 15px;
    background-repeat: no-repeat;
    /* Adjust as needed */
    width: 15px;
    z-index: 2;
    height: 15px;
  }
  .showHidePW{
    width: 40px;
    height: 40px;

    display: flex;
    position: absolute;
    justify-self: center;
    align-self: flex-end;
    
  }
  .pwContainer{
    display: flex;
    flex-direction: column;
    width: 'stretch'
  }
  .showPwIcon{
    width: stretch;
    height: stretch;
    padding: 10px;
    justify-self: center;



    

}