@import "../../globalVariables";
.remotoPlayback {
  display: flex;
  align-items: center;
  background-color: var(--mine-shaft);
  overflow: hidden;
  flex-direction: column;
  text-align: center;
  color: var(--swiss-coffee);
  font-family: var(--font-proxima-nova);
  max-height: 100vh;
 
}

.left {
  text-align: left;
}
.right {
  text-align: right;
}
.scrollContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 75px); /* Sets the minimum height to 100% of the viewport height */
  height: auto;
  flex-grow: 1;
  justify-content: flex-start;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-top: 50px;
  
 

  width: 100vw;
  max-width: 100vw;
}
.remotosystemlogoIcon{
  width: 70vw;

  padding-bottom: 20px;
  max-width: 600px;
  min-width: 400px;
  @include mobile {
    min-width: 85vw;
  }
}

.whyRemoto {
  margin: 0 !important;
  align-items: stretch;
  justify-content: center;
  width: auto;
  
  text-align: center;
  position: relative; 
  font-size: 150px;
  letter-spacing: 0.02em;
  font-weight: 700;
  font-family: inherit;
  color: transparent; 
  -webkit-text-stroke: 2px #8a8a96;
  z-index: 2;
  @include mobile {
    font-size: 100px;
    padding-bottom: 40px;
  }
}



.header {
  padding: 100px 0 0;
  &FrameBack {
    position: relative;
    z-index: 10;
    &:after {
      content: "";
      position: absolute;
      background: url("../../../public/backgrounds/headerRemotoLinesBg.png");
      width: 150%;
      height: 100%;
      top: 700px;
      left: -200px;
      right: 0;
      z-index: -1;
      background-repeat: no-repeat;
    }
    // &:before {
    //   content: "";
    //   position: absolute;
    //   background: url("../../../public/backgrounds/headerOrangeBg.png");
    //   width: 200%;
    //   height: 90%;
    //   top: 400px;
    //   left: -180px;
    //   right: 0;
    //   z-index: -1;
    //   background-repeat: no-repeat;
    // }
  }
  &Title {
    align-self: stretch;
    position: relative;
    font-size: 30px;
    text-align: center;
    width: 100%;
    z-index: inherit;
    margin-bottom: 40px;
    &Bold {
      font-weight: 500;
    }
    @include mobile {
      font-size: 30px;
    }
  }

  &Button {
    border-radius: var(--br-3xs);
    font-weight: 500;
    font-size: 24px;
    cursor: pointer;
    border: 0;
    padding: 6px var(--padding-3xs);
    background: linear-gradient(
      180deg,
      rgba(251, 101, 32, 0.86) 31.12%,
      rgba(251, 101, 32, 0.72) 75.75%
    );
    width: 240px;
    height: 50px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    box-shadow: inset 1px 1px 2px 0 rgba(250, 132, 14, 0.825),
      inset -1px -1px 5px 0 rgb(121, 70, 4);
    margin-bottom: 40px;
    &:hover {
      color: #fff;
      cursor: pointer;
      background-color: var(--color-coral);
      box-shadow: 1px 1px 2px 0 rgba(250, 132, 14, 0.825),
        -1px -1px 2px 0 rgb(241, 137, 0);
    }
    &:active {
      box-sizing: border-box;
      background-color: var(--color-coral);
      box-sizing: border-box;
      box-shadow: inset 1px 1px 5px 0 rgba(58, 58, 58, 0.825),
        inset -1px -1px 5px 0 rgb(216, 123, 0);
    }
  }
  &Image {
    flex: 1;
    position: relative;
    width: 100%;
    overflow: visible;
    height: auto;
    max-width: 1200px;
    object-fit: contain;
    border-radius: var(--br-3xs);
    margin-bottom: 140px;

    @include desktop-md {
      margin-bottom: 120px;
    }
    @include tablet {
      margin-bottom: 100px;
    }
    @include mobile {
      margin-bottom: 80px;
    }
  }

  .feats {
    margin-bottom: 124px;

    &Block {
      display: flex;
      justify-content: center; // Center items
      width: 100%;
      max-width: 1400px; // Set max-width for the block
      min-width: 400px; // Set min-width for the block
      margin: auto; // Center the block horizontally
      margin-bottom: 10px;
      gap: 60px;
      
      &:last-child {
        margin-bottom: 0;
      }
  
      @include desktop-md {
        flex-direction: column;

        margin-bottom: 50px;
      }
      @include tablet {
        flex-direction: column;
        margin-bottom: 20px;
      }
      @include mobile {
        flex-direction: column;
        margin-bottom: 40px;
        max-width: 85vw;
        min-width: 0px;

      }
      &Rev {
        display: flex;
       
        justify-content: center; // Center items
        width: 100%;
        max-width: 1400px; // Set max-width for the block
        min-width: 400px; // Set min-width for the block
        margin: auto; // Center the block horizontally
        margin-bottom: 10px;
        gap: 60px;
        @include desktop-md {
        flex-direction: column-reverse;
        }
        @include tablet {
          flex-direction: column-reverse;
          }
          @include mobile {
            flex-direction: column-reverse;
            max-width: 85vw;
            min-width: 0px;
            }
      }
      
    }

  
    &TextBlock {
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 590px; // Existing max-width
      margin: auto; // Center the text block
      &Title {
        margin: 0;
        align-self: stretch;
        position: relative;
        font-size: inherit;
        font-weight: 700;
        font-family: inherit;
        font-size: 34px;
        margin-bottom: 20px;
        color: #ddd8d8;
        @include desktop-md {
          margin-bottom: 30px;
          
        }
        @include tablet {
          margin-bottom: 30px;
        }
        @include mobile {
          margin-bottom: 30px;
        }
      }
      &Text {
        font-size: var(--title-24-bold-size);
        line-height: 1.5;
        max-width: 500px;
      }
  
      @include desktop-md {
        max-width: 680px;
        margin-bottom: 30px;
      }
      @include tablet {
        max-width: 550px;
        margin-bottom: 30px;
      }
      @include mobile {
        margin-bottom: 30px;
      }
    }
    
    &Image {
      max-width: 500px; // Keep or adjust as needed
      width: 100%;
      height: auto;
      object-fit: fill;
      margin: auto; // Ensure the image is centered
    }
  
    @include desktop-md {
      margin-bottom: 40px;
    }
    @include tablet {
      margin-bottom: 30px;
    }
    @include mobile {
      margin-bottom: 30px;
      width: 85vw;
    }
  }
}

.perfectBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  padding: 180px 0;
  max-width: 1500px;
  min-width: 950px;
  &:after {
    content: "";
    position: absolute;
    top: 90px;
    height: 82%;
    width: 100vw;
    background: #fb6520;
    transform: skew(0deg, -6deg);
  }

  .content {
    z-index: 1;
    width: 100%;
    margin-top: 20px;
    &Row {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: center;
      
      width: inherit;
      margin-bottom: 30px;
      gap: 100px; // This ensures that the space between items is managed

      @include mobile {
        gap: 0px;
      }
    }
    &Title {
      font-size: 80px;
      color: var(--mine-30);

      @include mobile {
        font-size: 50px;
        margin-bottom: 20px;
      }
    }
    &Image {
      max-width: 400px;
      width: 100%;
      height: auto;
      object-fit: contain;
    }
    &Description {
      font-size: 30px;
      color: var(--mine-30);
      max-width: 950px;
      margin: auto; /* Centers the block horizontally */
      text-align: center; /* Centers the text inside the block */
    }
  }

  @include mobile {
    padding: 160px 0;
  }
}
.funnelSubHeader{
  color: var(--swiss-coffee);
  display: flex;
  width: 50%;
  
  flex-direction: column;
  text-align: center;
  font-size: x-large;
  padding-top: 30px;
  align-self: center;
  font-size: large;
 

}
.pricingComponent{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: stretch;

  margin: 40px;
  margin-bottom: 120px;
}
.pricingHeadline{
  align-self: center;
  display: flex;
  color: var(--swiss-coffee);
  font-size: 50px;
  font-weight: bold;
  flex-direction: column;
}

.pricingComponent::before{
  content: "";
  background-color: var(--orange);
  width: 400%;
  align-self: center;
  height: 5px;
  border-radius: 100px;
  margin: 150px;
  transform: rotate(-5deg)
}

.features{
  display: flex;
  flex-direction: column;
  padding: 100px;
  height: auto;
  flex-grow: 1;
}

.pricing {
  padding: 60px 0;
  &Frame {
    padding: 80px;
    background: url("../../../public/backgrounds/pricingFrameBg.jpg");
    background-size: cover;
    border-radius: 50px;
    z-index: 99;
    &Back {
      position: relative;
      z-index: 100;
      &:after {
        content: "";
        position: absolute;
        background: url("../../../public/backgrounds/pricingOrangeBg.png");
        width: 100%;
        height: 90%;
        top: 200px;
        left: 1200px;
        transform: rotate(180deg);
        right: 0;
        z-index: -1;
      }
    }

    @include desktop-md {
      padding: 30px;
    }
    @include tablet {
      padding: 50px;
    }
    @include mobile {
      padding: 50px;
    }
  }
  .textBlock {
    margin-bottom: 60px;
    &Title {
      font-weight: 500;
      font-size: 60px;
      line-height: 2;
      margin-bottom: 20px;
    }
    &Description {
      font-size: 24px;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &PlansRow {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin-bottom: 60px;

    @include tablet {
      justify-content: center;
    }
  }
  .planBox {
    width: 340px;
    height: 525px;
    box-shadow: 5px 5px 8px 0px #202020;
    box-shadow: -2px -2px 2px 0px #fb65201a;
    border-radius: 16px;
    border: 1px solid #48485c;
    justify-content: space-between;

    &Top {
      height: 198px;
      padding: 25px 40px 15px 20px;
      background: linear-gradient(180deg, #202020 0%, #48485c 100%);
      border-top-right-radius: 15px;
      border-top-left-radius: 15px;

      @include desktop-md {
        padding: 20px 30px 5px 10px;
      }
    }
    &Bottom {
      padding: 20px 20px 20px;
      background: #313139;
      border-bottom-right-radius: 15px;
      border-bottom-left-radius: 15px;
      height: calc(100% - 198px);

      @include desktop-md {
        padding: 10px 20px 25px;
      }
    }
    &Logo {
      margin-bottom: 20px;
    }
    &Title {
      color: #fb6520;
      font-size: 16pt;
      font-weight: 500;
      padding: 0;
      margin: 0;
      align-self: center;
      text-align: center;
    }

    @include desktop-md {
      width: 280px;
    }
    @include tablet {
      margin-bottom: 40px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    @include mobile {
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0;
      }
    }

    .planPrice {
      &Row {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 55%;
      }
      &Amount {
        font-size: 36pt;
        font-weight: 500;
        color: #ddd8d8;
      }
      &Contract {
        font-size: 45px;
        font-weight: 500;
        color: #ddd8d8;
      }
      &Description {
        text-align: left;
        font-size: 15px;
        font-weight: 400;
        padding-left: 10px;
        line-height: 1.5;
      }
    }
    .planDetails {
      &Block {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        height: 75%;
      }
      &List {
        text-align: left;
        list-style: none;
        list-style-position: inside;
        padding: 0;
      }
      &Point {
        font-size: 15px;
        font-weight: 400;
        line-height: 2;
        margin-bottom: 10px;
        &:before {
          content: "";
          background: #fb6520;
          width: 7px;
          height: 7px;
          border-radius: 50%;
          margin: 2px 5px 2px 5px;
          display: inline-block;
          top: 50%;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
      &Button {
        font-size: 15px;
        line-height: 1.5;
        font-weight: 700;
        width: 100%;
        padding: 10px 0;
        text-align: center;
        border-radius: 10px;
        background-color: #fb6520;
        text-transform: capitalize;
        &:hover {
          cursor: pointer;
          color: var(--swiss-coffee);
        }
      }
    }
  }
  .needMoreTime {
    &Title {
      font-weight: 700;
      margin-bottom: 10px;
    }
    &Title,
    &Description {
      font-size: 24px;
      line-height: 1;
    }
  }
}
.testDrive {
  padding: 85px 0;
  &Title {
    color: #ddd8d8;
    font-size: 60px;
    font-weight: 700;
    line-height: 2;

    @include desktop-md {
      font-size: 55px;
    }
    @include tablet {
      font-size: 50px;
      line-height: 1.5;
      margin-bottom: 30px;
    }
    @include mobile {
      font-size: 40px;
      line-height: 1.5;
      margin-bottom: 30px;
    }
  }
  &Description {
    color: #ddd8d8;
    font-size: 24px;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 30px;
  }
  &Button {
    width: 100%;
    padding: 20px 0;
    border-radius: 15px;
    font-weight: 500;
    background: #fb6520;
    text-align: center;
    font-size: 25px;
    line-height: 1.5;
  }

  @include desktop-md {
    padding: 15px 0 85px;
  }
  @include tablet {
    padding: 15px 0 85px;
  }
  @include mobile {
    padding: 15px 0 65px;
  }
}

