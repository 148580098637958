.remotosystemlogoIcon {
  position: relative;
  width: 200px;
  height: 100%;
}
.div {
  position: relative;
  font-size: var(--body-15-regular-size);
  line-height: 20px;
  font-family: var(--font-proxima-nova);
  color: var(--swiss-coffee);
  text-align: center;
}
.menuButton1 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  align-self: stretch;
  width: 114px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}


.logoButton{
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 300px;
  height: 100%;
  background-color: transparent;
  cursor: pointer;
}

.logoButton:hover{
    color: var(--orange)

}
.logoButton:active{
  background-color: var(--orange);
  box-shadow:  1px 1px 2px 0 rgba(250, 132, 14, 0.825), -1px -1px 2px 0 rgb(241, 137, 0);
}
.menuButton1:hover {
  color: var(--orange)


}
.menuButton1:active {
  background-color: var(--orange);
  box-shadow: inset 4px 4px 8px 0 rgba(186, 78, 0, 0.5), -4px -4px 8px 0 rgb(252, 126, 0);

}
.div1 {
  position: relative;
  line-height: 20px;
  font-size: medium;

}
.menuButton2:visited{
  color: unset;
}
.menuButton2, .menuButton2_selected {
  align-self: stretch;
  width: 120px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: var(--body-strong-17-bold);
  color: var(--manatee) !important; 
  text-decoration: none;
}


.menuButton2_selected {
  color: var(--swiss-coffee)!important 
}
.menuButton2:hover {
  color: var(--swiss-coffee)


}
.menuButton2:active {
  color: var(--orange)

}


.div5 {
  position: relative;
  font-size: var(--body-15-regular-size);
  line-height: 20px;
  font-family: var(--font-proxima-nova);
  color: var(--pure-white);
  text-align: center;
}
.menuButton6 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background: linear-gradient(
    180deg,
    rgba(251, 101, 32, 0.86) 31.12%,
    rgba(251, 101, 32, 0.72) 75.75%
  );  border-radius: var(--br-5xs);
  width: 114px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-shadow:   inset 1px 1px 2px 0 rgba(250, 132, 14, 0.825), inset -1px -1px 5px 0 rgb(121, 70, 4);
}
.menuButton6:hover {
  color: var(--orange)


}
.menuButton6:active {
  box-sizing: border-box;
  box-shadow:   inset 1px 1px 5px 0 rgba(58, 58, 58, 0.825), inset -1px -1px 5px 0 rgb(216, 123, 0);

}
.line6Stroke {
  position: relative;
  width: 19px;
  height: 3px;
}
.icBurguermenu,
.line6StrokeParent {
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.line6StrokeParent {
  display: flex;
  gap: var(--gap-9xs);
}
.icBurguermenu {
  cursor: pointer;
  border: 0;
  padding: 10px;
  background-color: transparent;
  width: 24px;
  height: 24px;
  display: none;
}
.icBurguermenu:hover{
  
  background-color: var(--mine-30);
}
.burgerButton, .burgerButton_selected {
  
  
  padding: 10px;
 
  text-align: center;
  font-family: var(--font-proxima-nova);
  background-color: var(--mine-shaft);
  border-bottom: 1px solid var(--tuna);
  border-top: 1px solid var(--tuna);

  z-index: 100;
  color: var(--manatee) !important;
  text-decoration: none;
  width: 300px;
}
.burgerButton_selected {
  color: var(--swiss-coffee) !important;
}
.burgerButton:hover{
  color: var(--swiss-coffee) !important;
  background-color: var(--mine-30);
}


.burgerParent{
    z-index: 10;
    position: absolute;
    flex-direction: column;
    display: flex;
    height: auto;
    width: 400px;
    top: 75px;
   
    align-self: center;
    align-items: center;
  }

  .rightLinks {
  flex-direction: row;
  gap: var(--gap-base);
}
.rightLinks,
.topBar,
.topBarButtonsFrame {

  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
}
.topBarButtonsFrame {
  display: flex;
  
  width: stretch;
  max-width: 95vw;
  justify-content: space-evenly;
  margin-left: auto; 
  margin-right: auto;
  flex: 1;
  flex-direction: row;

}
.topBar {
  position: fixed; /* Anchors the top bar to the top of the viewport */
  top: 0; /* Ensures the top bar is at the very top of the viewport */
  width: 100%; /* Ensures the top bar spans the entire width of the viewport */
  max-width: 100vw;
  background-color: var(--mine-shaft);
  height: 75px;
 
  z-index: 100; /* Ensures the top bar stays above other content */
  display: flex;
  align-items: center;
  justify-content: center;
}



@media screen and (max-width: 900px) {
  .menuButton1,
  .menuButton2,
  .menuButton2_selected,
  .menuButton3,
  .menuButton4,
  .menuButton6 {
    display: none;
  }
  .icBurguermenu {
    display: flex;
  }


 
}
