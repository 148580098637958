.outerContainer{
    width: auto;
    height: auto;
    max-width: 100vw;
    min-height: 100vh;
    background-color: var(--mine-shaft);
    justify-content: space-between;
    align-items: center;
    display: flex;
    flex-direction: column;

}

.innerContainer {

    width: auto;
    height: auto; 
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: transparent;
    justify-items: space-evenly;
    align-items: center;
    display: flex;
    color: var(--swiss-coffee);
    text-align: center;
    flex-direction: column;

    

}
.helpPage{
display: flex; 
height: 100vh;
flex-direction: column; 
align-items: space-between;
}