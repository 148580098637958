
.parentComponent{
    height: 100vh;
    overflow: hidden;
}
.mainComponent{

   
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 0px 50px;
    height: calc(100vh - 75px);
    width: stretch;
    margin-top: 75px;
   
    overflow-y: scroll;
    align-self: center;
    background-color: var(--mine-shaft);
    font-family: 'Proxima Nova', sans-serif;
}
.header{
    margin-top: 50px;
    font-size: 30px;
    margin-bottom: 20px; 
    padding-bottom: 20px; 
    width: 90vw; 
    max-width: 1200px; 
    text-align: left; 
    font-weight: 400; 
    display: flex; 
    
    border-bottom: 1px solid var(--mako); 
}
.mainChild{
    display: flex;
    flex-direction: column;  
    align-items: center;
    
    height: stretch;
    align-self: center;
    width: 800px;

}

.contentContainer{
    display: flex;
    flex-direction: column;
    max-width: 100vw;
    width: 800px;
    overflow-y: auto;
    overflow-x: hidden;
    height: fit-content;
    margin-bottom: 75px;
    justify-content: flex-start;
    align-items: start;
}
.navContainer{
    display: flex;
    flex-direction: row;
    padding: 0px;
    gap: 20px;
    width: 1200px;
    height: auto;

}
.titleText{
    color: var(--swiss-coffee);
    font-size: 18px;
    font-weight: 400;
    margin: 0px;
}
.titleContainer{
    color: var(--swiss-coffee);
    width: 800px;
    max-width: 85vw;
    margin-bottom: 20px;
    height: fit-content;


}

.titleHeader{
    color: var(--swiss-coffee);
    align-self: start;
    text-align: left;
    font-size: 20px;
    margin:20px 0px 20px 0px;

}
.buttonText{
    color: var(--swiss-coffee);
    font-size: 14px;
    text-align: left;
    font-weight: 400;
    padding: 10px 0px 10px 0px;

}

.jobContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: auto;
    box-shadow: inset -5px -5px 10px rgba(0, 0, 0, 0.106), inset 5px 5px 10px  rgba(255, 255, 255, 0.093);
    background-color: var(--tuna);
    border: 1px solid var(--mako);
    border-radius: 15px;
    padding: 40px;
    height: 100%;
    min-height: fit-content;
    transition: all 0.3s ease;
    
}
.contactLink{
    padding: 10px;
    background-color: var(--tuna);
        border-radius: 15px;
    text-decoration: none;
    border: 1px solid var(--orange);
    color: var(--orange);
    width: fit-content;
    align-self: flex-start;
    margin: 20px;
    font-size: 18px;
    
    
}
.contactLink:hover:link{
    background-color: var(--mako);
    color: var(--orange);
}
.contactLink:link{
    color: var(--orange);

}
.horizontal{
    display: flex;
    flex-direction: row;
 

}
.xButton{
    background-color: var(--tuna);
    color: var(--orange);
    border: 1px solid var(--orange);
    border-radius: 1000px;
    padding: 10px;
    font-size: 30px;
    line-height: 15px;
    margin: 10px;
    font-weight: bolder;
    justify-content: center;
    display: flex;
    align-items: center;
    align-self: center;
    width: fit-content;
    height: fit-content;
    cursor: pointer;
    transform: rotate(45deg);
    transition: all 0.3s ease;

}
.sel_card{
    
    padding: 0px 20px 0px 20px;
    background-color: var(--tuna);
    text-align: left;
    align-items: start;
    align-self: start;
    color: var(--swiss-coffee);
    border-bottom: 0px solid var(--mako);
    width: 100%;
    
}
.card{
    padding: 0px 20px 0px 20px;

    text-align: left;
    align-items: start;
    align-self: start;
    color: var(--swiss-coffee);
    border-bottom: 1px solid var(--mako);
    width: 100%;
}
.card:hover{
    background-color: var(--mako);
    color: var(--tuna);
}
.buttonsContainer{
    display: flex;
    flex-direction: column;
    align-self: start;
    min-width: 200px;
}
.cardDetails{
    display: flex;
    flex-direction: column;
    margin: 10px;
    align-items: start;
    justify-content: space-evenly;
    color: var(--swiss-coffee);
    width: auto;
    height: stretch;
    gap: 20px;
    text-align: left;
    animation: fadeIn 1s ease-in;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--mako);
}

@keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }

}
.actionTextContainer{
    display: flex;
    justify-content: start;
    align-items: left;
    flex-direction: column;
    
    animation: fadeIn 1s ease-in;
    color: var(--swiss-coffee);
    
    width: stretch;
    height: fit-content;
    
    border-radius: 15px;
}
.subTitle{
    color: var(--swiss-coffee);
    
    font-size: 24px;

}
.signature{
    color: var(--swiss-coffee);
    padding-left: 20px;
    font-size: 24px;
    padding-bottom: 20px;
    align-self: center;

}
.signatureSmall{
    color: var(--swiss-coffee);
    padding-left: 20px;
    font-size: 18px;
    padding-bottom: 5px;
    align-self: flex-end;
}
.text{
    color: var(--swiss-coffee);
    text-align: left;
    max-width: 600px;
    align-self: center;

}
.fadeIn {
    animation: fadeIn 1s ease-in;
}
@media screen and (max-width: 1400px) {
    .navContainer{
        width: 90vw;
  
    }
  
    
}

@media screen and (max-width: 540px) {
    .parentComponent{
        overflow: auto;
    }
    .mainComponent{
        width: 100vw;
        align-items: start;
        height: fit-content;
        overflow-y: scroll;
        min-height: fit-content;
        padding: 0px;
        overflow-y: scroll;
    }
    .mainChild{
        flex-direction: column;
        width: 100vw;
        margin: 0px;
    }

    .buttonsContainer{
        flex-direction: column;
        align-self: center;
        width: 85vw;
    }
    .cardDetails{
        align-self: center;
        width: 85vw;
        
    }
    .jobContainer{
        width: 100vw;
        align-self: center;
        
    }
    .actionTextContainer{
        width: 85vw;
        align-self: center;
    }
    .titleContainer{
        width: 85vw
    }
    .navContainer{
        padding: 10px;
        flex-direction: column;
        align-self: center;
    }
    .contentContainer{
        height: fit-content;
        width: 85vw;

    }
   
}