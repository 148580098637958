.square {
    cursor: pointer;
    display: flex;
    background-color: var(--mine-30);
    border-width: 0;
    border-radius: 14.09px;
    box-shadow:   inset 1px 1px 2px 0  rgba(255, 255, 255, 0.15), inset -1px -1px 5px 0 #202020;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: stretch;
    height: 200px;
    flex-direction: column;
}
.radial-menu {
    position: relative;
    width: 200px; /* Control size of the circle */
    height: 200px; /* Control size of the circle */
    border-radius: 50%;
    margin: 50px auto; /* Centering the menu */
}

.slice {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100px; /* Half of the circle's diameter */
    height: 100px; /* Half of the circle's diameter */
    background-color: skyblue;
    border: none;
    clip-path: polygon(50% 50%, 100% 0, 100% 100%);
    transform-origin: 0 100%;
    transition: background-color 0.3s, transform 0.3s;
}

.slice:hover,
.slice.active {
    background-color: deepskyblue;
}
.square:hover {
    background-color: var(--gun-powder);
    box-sizing: border-box;
}

.square:hover .text1 {
    color: var(--swiss-coffee);
}

.square:active {
    box-shadow:   inset 1px 1px 5px 0 rgba(58, 58, 58, 0.825), inset -1px -1px 5px 0 rgba(255, 255, 255, 0.15);

}


.featuresGroup {
    display: flex;
   
    align-items: center;
    justify-content: center;
    color: var(--manatee);
    align-self: center;
   
    font-weight: 200;
    letter-spacing: 0;
    line-height: 38.5px;
    text-align: left;
    font-size: 8pt;
}
.featuresMacro{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


}
.selected {
  
    background: linear-gradient(rgba(27, 24, 24, 0.825), rgba(34, 32, 32, 0.15)),
    linear-gradient(
      20deg,
      rgba(29, 24, 24, 0.825) 31.12%,
      rgba(19, 16, 16, 0.15) 75.75%
    );
    align-items: center;
    justify-content: center;
    color: var(--manatee);
    src: url("/public/fonts/ProximaNova-Light.ttf");
    font-weight: 300;
    letter-spacing: 0;
  line-height: 38.5px;
 
  background-color: var(--mine-30);
  border-radius: 14.09px;
 box-shadow:   inset 1px 1px 5px 0 rgba(58, 58, 58, 0.825), inset -1px -1px 5px 0 rgba(255, 255, 255, 0.15);

  box-sizing: border-box;
  

}

.featuresGroup {
    flex-direction: row;
    box-sizing: border-box;
    font-size: 260px;
    color: var(--mine-shaft);
    align-self: center;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 200px));
    grid-gap: 20px;
    width: 75vw;
    
}

.featuresGroup {

    box-sizing: border-box;
}

.listText {
    border: 0px none;

    color: var(--swiss-coffee);
    font-family: var(--font-proxima-nova);
    font-size: 17px;
    font-weight: 400;
    text-align: left;
    line-height: 30px;
    padding: 10px 0px 10px 0px;


}
.featureList{
    border-left: 2px solid #FB6520;
    padding-left: 3vw;
    width: 150%;
    min-height: 20vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.line {
    height: 100%;
    width: 2px;
    background-color: #FB6520;
    margin-left: 10vw;
    margin-right: 2vw;

}
.scrollTarget{
    transform: translate(0, 15vh);
}

@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
    
}

.selectedContainer {
    
    opacity: 1;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
   
    
    width: 1100px;
    gap: 20px;
    margin-top: 5%;
    margin-bottom: 5%;
    animation: fadeIn 1.0s ease;

}
.securityText{
    text-align: left;
    align-self: flex-start;
    width: stretch;
    font-weight: 400;
    font-size: 17px;
    

}
.featureTextContainer{
    display: flex;
    flex-direction: column;
    align-self: center;
    
    height: stretch;
    width: stretch;


}
.opaqueContainer {
    opacity: 0;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: center;
 
    width: 85vw;
    height: 20vh;
    margin-top: 15%;
    margin-bottom: 10%;
    transition: opacity 1.0s ease; 
}

.selectedFeatureText {
    color: var(--swiss-coffee);
    font-family: var(--font-proxima-nova);
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 60px;
    text-align: left;
    width: stretch;
    line-height: normal;
    text-transform: capitalize;


}

.cardTitleText{
    color: var(--manatee);
    font-family: var(--font-proxima-nova);
    font-size: medium;
    font-weight: 300;
    text-transform: capitalize;
    padding: 10px;
    
    line-height: normal;
    
}


.isolationModeIcon1{
    padding-top: 50px;
    
    width: 30%;
}

@media screen and (max-width: 420px) {
    .selectedContainer{
        max-width: 100vw;
        height: auto;
        flex-direction: column;
        padding-top: 20px;
        
       
    }
    .selectedFeatureText{
        
        font-size: 24pt;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 30px;
        text-align: center;
    }
    .securityText{
        font-size: 14pt;
        
  
    }
    .listText {
        border: 0px none;
    
        color: var(--swiss-coffee);
        font-family: var(--font-proxima-nova);
        font-size: 12pt;
        font-weight: 300;
        text-align: left;
        letter-spacing: 0;
        line-height: 20px;
       
    
    }
    .featureList{
        width: 90vw;
        padding-left: 10vw;
    }
    .line {
        height: 2px;
        width: 40vw;
        background-color: #FB6520;
        
    
    }
   
    .square:hover {
        background-color: var(--gun-powder);
        box-sizing: border-box;
    }
    
    .square:hover .text1 {
        color: var(--swiss-coffee);
    }
    
    .square:active {
        box-shadow:   inset 1px 1px 5px 0 rgba(58, 58, 58, 0.825), inset -1px -1px 5px 0 rgba(255, 255, 255, 0.15);
    
    }



button:hover{
    color: var(--swiss-coffee) !important
}

}