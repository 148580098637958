.contentContainer{
min-height: calc(100vh - 125px);
height: min-content;
margin-top: 75px;
display: flex;

justify-content: start;

flex-direction: column;
background-color: var(--mine-shaft);
display: flex;

}