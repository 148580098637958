


.buttonOff, .buttonSelected{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    color: #DDD8D8;
    border-width: 1px;
    border-bottom: 1px solid var(--mako);
    background-color: transparent;
    border-style: solid;
  
    width: stretch;
    height: 50px;
    font-size: 14px;
    text-align: left;
    min-width: fit-content;
    border-radius: 0px;
}

.buttonSelected{
    background-color: var(--tuna);
}

.buttonSelected:hover{
    .downloadPdfButton{
        visibility: visible;
    }
}

.legalParent{
    display: flex; 
    align-items: center;
    justify-content: start;
    flex-direction: column; 
    margin-top: 75px;
    height: calc(100vh - 120px);

    padding: 0px 50px;
    width: stretch;
    background-color: var(--mine-shaft);
}
.horizontal{
    display: flex;
    width: 1200px;
    align-items: start;
    align-self: center;
    justify-content: center;
    flex-direction: row;
    height: fit-content
}
.header{
    width: stretch;
    text-align: left;
    align-self: center;
    font-size: 20px;
    padding: 10px;
   
    margin-top: 20px;
    border-bottom: 2px solid var(--mako);

   
}
.downloadPdfButton{
    border-radius: 10px;
    border-width: 1px;
    visibility: hidden;

    background-color: transparent;
    border-style: none;
  
    flex-direction: row;
    padding: 5px;
    width: fit-content;
    align-self: center;
    margin: 5px;
}
.legalChild{
   
  
    
    height: fit-content;
    display: grid;
    grid-template-columns: repeat(1, 10vw);

}
.docFrame{
    display: 'flex'; 
    overflow: 'auto';
    border: '0px';
    width: "50vw"; 
    height: auto;
}

.iFrame{
    display: flex;
    padding-top: 10px;
    padding-left: 40px;
    width: 85%;
    align-items: center;
    margin-bottom: 20px;
    height: 70vh;
    
}
.headerTop{
    margin-top: 50px;
    font-size: 30px;
    margin-bottom: 20px; 
    padding-bottom: 20px; 
    width: 90vw; 
    max-width: 1200px; 
    text-align: left; 
    font-weight: 400; 
    display: flex; 
    border-bottom: 1px solid var(--mako); 
}

@media screen and (max-width: 1200px){
    .legalChild{
        /* flex-direction: column; */
        grid-template-columns: repeat(1, 1fr);

        align-items: center;
        height: fit-content;
    }
    .horizontal{
        width: 90vw;
        height: fit-content
    }
    .legalParent{
        height: fit-content;
    }
    .iFrame{
        height: 100vh;
    }
    
}

@media screen and (max-width: 1024px){
    .legalChild{
        /* flex-direction: column; */
        grid-template-columns: repeat(1, 1fr);
 
        align-items: center;
    }
    .horizontal{
        flex-direction: column;
        width: 80vw;
        align-items: stretch;
    }
    .iFrame{
        width: auto;
    }
    
    
}


@media screen and (max-width: 612px){
    .legalParent{
        height: fit-content;
        padding-top: 10px;
        overflow-y: auto;
    }
    .legalChild{
        /* flex-direction: column; */
        grid-template-columns: repeat(1, 1fr);

    }
    .horizontal{
        flex-direction: column;
        width: 80vw;
        align-items: stretch;
    }
    .iFrame{
        width: auto;
    }
    
}