.designedWithThe,
.workTogetherLike {
  margin: 0;
  position: relative;
  width: 1200px;
}
.workTogetherLike {
  font-size: inherit;
  font-weight: 700;
  font-family: inherit;
  background: linear-gradient(90.07deg, #ffa277, #fb6520);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}
.designedWithThe {
  font-size: var(--font-size-16xl);
}
.comingSoon,
.designedWithThe,
.titleframe {
  display: flex;
  align-items: center;
  justify-content: center;
}
.comingSoon {
  margin: 0;
  position: relative;
  font-size: var(--font-size-16xl);
  font-weight: 700;
  font-family: inherit;
  color: var(--orange);
  width: 1200px;
}
.titleframe {
  flex-direction: column;
  padding: 0 0 var(--padding-30xl);
  gap: var(--gap-31xl);
}
.remotoUiFade1Icon {
  position: relative;
  border-radius: 6.26px;
  width: 1200px;
  height: 633.13px;
  flex-shrink: 0;
  object-fit: cover;
}
.uiMask {
  width: 1240px;
  display: flex;
  flex-direction: column;
  padding: var(--padding-xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  gap: var(--gap-41xl);
}
.toNabShow {
  color: var(--swiss-coffee);
}
.areYouAttendingContainer {
  color:#fb6520;
  width: 100vw;
  margin: 0;
  align-self: center;
  position: relative;

  text-align: center;
  justify-content: center;
 
  font-size: var(--font-size-41xl);
  font-weight: 700;
  font-family: inherit;
}
.scheduleTheTimeContainer {
  margin: 0;
 
  font-size: 85%;
  color: var(--swiss-coffee);
}
.didntFindA {
  font-family: var(--font-arial);
}
.meetsremotoprocom1 {
  text-decoration: underline;
}
.meetsremotoprocom {
  color: inherit;
}
.didntFindAContainer {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: 20pt;
  color: inherit;
}
.textframe {
  flex: 1;
  width: 50vw;
  display: flex;
  flex-direction: column;
  font-size: 75%;
  box-sizing: border-box;
  align-self: center;
  justify-content: center;
  gap: var(--gap-31xl);
}
.meetatnabremoto21Icon {
  position: relative;
  border-radius: var(--br-11xl);
  width: 588px;
  height: 508px;
  flex-shrink: 0;
  object-fit: cover;
}
.framecalendly {
 
  background-color: var(--swiss-coffee);
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: -1.202330231666565px -1.202330231666565px 6px rgba(255, 255, 255, 0.722),
    1.40466046333313px 1.40466046333313px 6px #d3d3d3;
}
.centerFrame,
.textframeParent {
  display: flex;
  align-items: center;
  justify-content: center;
}
.textframeParent {
  width: 1171px;
  flex-direction: row;
  gap: var(--gap-xl);
  text-align: left;
  font-size: var(--font-size-31xl);
  color: var(--orange);
}
.centerFrame {
  flex-direction: column;
  padding: var(--padding-xl);
  gap: var(--gap-31xl);
  text-align: center;
  font-size: var(--font-size-41xl);
  color: var(--swiss-coffee);
  font-family: var(--font-arial);
  margin-top: 10vh;

}
@media screen and (max-width: 1200px) {
  .designedWithThe,
  .workTogetherLike {
    align-self: stretch;
    width: auto;
    flex: 1;
  }
  .titleframe {
    width: 80%;
    align-items: center;
    justify-content: flex-start;
  }
  .remotoUiFade1Icon {
    width: 80%;
    height: 80%;
  }
  .areYouAttendingContainer,
  .didntFindAContainer,
  .scheduleTheTimeContainer {
    width: 100%;
    flex: 1;
  }
  .textframe {
    flex: relative;
    align-self: stretch;
  }
  .textframeParent {
    width: 70%;
    flex-direction: column;
  }
  .centerFrame {
    width: auto;
    height: auto;
  }
}
@media screen and (max-width: 960px) {
  .titleframe {
    width: 60%;
  }
  .remotoUiFade1Icon {
    width: 60%;
    height: 60%;
  }
  .textframe {
    flex: unset;
    align-self: stretch;
  }
  .textframeParent {
    width: 55%;
    flex-direction: column;
  }
}
@media screen and (max-width: 540px) {
  .designedWithThe,
  .workTogetherLike {
    font-size: var(--font-size-16xl);
    align-self: stretch;
    width: auto;
    flex: 1;
  }
  .designedWithThe {
    font-size: var(--font-size-xl);
  }
  .comingSoon {
    font-size: var(--font-size-xl);
    transform: rotate(0deg);
  }
  .titleframe {
    width: 20%;
  }
  .remotoUiFade1Icon {
    display: none;
    width: 90%;
    height: 90%;
  }
  .areYouAttendingContainer {
    font-size: var(--font-size-11xl);
  }
  .didntFindAContainer,
  .scheduleTheTimeContainer {
    font-size: var(--font-size-mini);
  }
  .didntFindAContainer {
    align-self: stretch;
    width: auto;
  }
  .textframe {
    width: auto;
    height: auto;
    gap: var(--gap-xl);
    flex: unset;
    align-self: stretch;
  }
  .framecalendly {
    width: 80vw;
    height: fit-content;
    align-items: center;
    justify-content: center;
   
  }
  .textframeParent {
    width: 80%;
    flex-direction: column;
  }
  .centerFrame {
    align-self: stretch;
    width: auto;
    flex: 1;
    gap: var(--gap-xl);
  }
  .scheduleTheTimeContainer{
    font-size: 24px;
  }
}
