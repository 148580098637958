.icRemotoIcon {
  position: relative;
  width: 25px;
  height: 25px;
  flex-shrink: 0;
  overflow: hidden;
}
.remotopro {
  position: relative;
  line-height: 20px;
  text-transform: capitalize;
}
.iconFrame,
.text,
.text1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.iconFrame,
.text1 {
  align-items: center;
  justify-content: center;
}
.iconFrame {
  gap: var(--gap-3xs);
}
.text1 {
  border-right: 1px solid var(--swiss-coffee);
}
.text1,
.text3 {
  text-decoration: none;
  padding: 0 var(--padding-3xs);
  color: inherit;
}
.links,
.text3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.text3{
  color: var(--swiss-coffee);
}
.text3:visited{
  color: var(--swiss-coffee);

}
.footerLinks {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.bottomFrame {
  width: stretch;
  background-color: var(--mine-shaft);
  border-top: 1px solid var(--manatee);
  display: flex;
  flex-direction: row;
  padding: 12.5px 20vw;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  max-width: 100vw;
  font-size: var(--font-size-mini);
  color: var(--swiss-coffee);
  font-family: var(--font-arial);
  height: 50px;
  font-size: 14px;

  
}

@media screen and (max-width: 960px) {
  .bottomFrame {
    padding: 20px 20px;
  }
}

@media screen and (max-width: 540px){

.bottomFrame{
  height: fit-content;
  gap: 20px;

  flex-direction: column-reverse;
  
}
  
}