.backToStore{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    font-size: 1.2rem;
    background-color: transparent;
    cursor: pointer;
    text-decoration: none;
    transition: color 0.3s;
    width: 400px;
    justify-content: flex-start;
}

.backArrow{
    height: 25px;

}
.backToStore:hover{
    background-color: transparent;
}
.backToStore:hover::after{
    content: 'Back to store';
    color: #FD6520;
 
    font-size: 14px;
    margin-left: 10px;
    font-style: normal;
    font-weight: 500;


}